import React from 'react'
import { Button } from '../../../components/button'
import { requestTraffics } from '../../../request/hooks/api'
import { sendMessageIsOpenOffer } from '../../../request/hooks/tg'
import '../free/style.scss'

export type Props = {
  id: number
  link: string
  offer: {
    buttonText: string
    description: string
    id: number
    image: {
      file: { url: string }
    }
    maxDay: number
    maxPercent: number
    maxPrice: number
    minDay: number
    minPercent: number
    minPrice: number
    title: string
    realLink: string
  }
  type: string
}

export const requestLink = ({
  id,
  link,
  target = '_blank',
  name,
  type,
  realId,
  realLink,
}: {
  id?: number
  link: string
  target?: string
  name: string
  type: string
  realId: number
  realLink: string
}) => {
  let params = new URL(link).searchParams
  requestTraffics(params.get('link'), params.get('operationHash')).then(
    link => {
      setTimeout(() => {
        //@ts-ignore
        window.open(link.data.link, target)
      }, 100)
      sendMessageIsOpenOffer({
        offerName: name,
        offerId: String(realId),
        offerLink: realLink,
        typeShowcase: type,
      })
    }
  )
}

export const Showcase = (props: Props) => {
  const [isPress, setIsPress] = React.useState(false)
  return (
    <div
      className={'card'}
      style={{ background: isPress ? '#EAEAEA' : '#fffff' }}
    >
      <div className={'card__logo'}>
        <img src={'https://moneytka.ru/' + props.offer.image.file.url} />
      </div>
      <div className={'card__desc'}>{props.offer.description}</div>
      <div className={'card__sum'}>
        {props.offer.minPrice} ₽ - {props.offer.maxPrice} ₽
      </div>
      <div className={'card__percent'}>
        <span>Ставка</span> от {props.offer.minPercent} %
      </div>
      <div className={'card__date'}>
        <span>Срок </span>
        {props.offer.minDay} до {props.offer.maxDay} дн.
      </div>
      <div className={'card__button'}>
        <Button
          theme={'yellow'}
          title={'Получить деньги'}
          onClick={() => {
            requestLink({
              id: Number(props.id),
              link: props.link,
              name: props.offer.title,
              type: props.type,
              realId: props.offer?.id,
              realLink: props.offer?.realLink,
            })
            setIsPress(true)
          }}
        />
      </div>
    </div>
  )
}
