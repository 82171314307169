//@ts-ignore
import PublicOffer from './files/public_offer.pdf'
//@ts-ignore
import Tariffs from './files/tariffs.pdf'
//@ts-ignore
import PersonalData from './files/personal_data.pdf'
//@ts-ignore
import PaymentApplicationAgreement from './files/payment_application_agreement.pdf'
//@ts-ignore
import AgreementProcessingPersonalData from './files/agreement_processing_personal_data.pdf'
//@ts-ignore
import AdvertisingAgreement from './files/advertising_agreement.pdf'

export const documents = [
  {
    id: 0,
    title: 'Согласие на получение рекламных материалов',
    link: AdvertisingAgreement,
  },
  {
    id: 1,
    title: 'Согласие на обработку персональных данных',
    link: AgreementProcessingPersonalData,
  },
  {
    id: 2,
    title: 'Публичная оферта',
    link: PublicOffer,
  },
  {
    id: 3,
    title: 'Соглашение с тарифами',
    link: Tariffs,
  },
  {
    id: 4,
    title: 'Обработка персональных данных',
    link: PersonalData,
  },
  {
    id: 5,
    title: 'Соглашение о рекуррентных платежах',
    link: PaymentApplicationAgreement,
  },
]
