import { CircularProgress } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router'
import { requestCheckPayment, requestMe } from '../../request/hooks/api'
import {
  sendMessageIsBingCard,
  sendMessageIsBingCardError,
} from '../../request/hooks/tg'

function CheckPayment({
  transactionHash,
  navigate,
  countNumber,
}: {
  transactionHash: string | null
  navigate: any
  countNumber: number
}) {
  requestCheckPayment(transactionHash)
    .then(() => {
      requestMe().then(response => {
        localStorage.setItem('ref', '0')
        localStorage.setItem('userInfo', JSON.stringify(response.data))
      })
      sendMessageIsBingCard()
      //@ts-ignore
      ym(67359901, 'reachGoal', 'finish')
      //@ts-ignore
      ym(93059549, 'reachGoal', 'show_case1')
      navigate('/profile/success', { state: { status: true, isPaid: true } })
    })
    .catch(error => {
      if (error.response.status === 403) {
        if (countNumber === 10) {
          // sendMessageIsBingCardError()
          return navigate('/profile/errors', {
            state: { status: false, isPaid: true },
          })
        }
        return (
          setTimeout(() =>
            CheckPayment({
              transactionHash,
              navigate,
              countNumber: countNumber + 1,
            })
          ),
          300
        )
      } else {
        // sendMessageIsBingCardError()
        navigate('/profile/errors', { state: { status: false, isPaid: true } })
      }
    })
}

const Payment = () => {
  const navigate = useNavigate()
  React.useEffect(() => {
    const url = new URL(document.URL)
    const transactionHash: string | null =
      url.searchParams.get('transactionHash')

    return CheckPayment({ transactionHash, navigate, countNumber: 0 })
  }, [])

  return (
    <div
      style={{
        height: '60vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <CircularProgress />
    </div>
  )
}

export default React.memo(Payment)
