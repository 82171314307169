import React from 'react'
import { requestLink } from '../showcase/components/showcase'

export const CardGoogle = ({
  desc,
  title,
  doubleTitle,
  decorLink,
  link,
  isAdvertising = false,
  id,
}: {
  id: number
  desc: string
  title: string
  doubleTitle?: string
  decorLink: string
  link: string
  isAdvertising?: boolean
}) => {
  return (
    <div className="searchresult">
      <span
        onClick={() =>
          requestLink({
            id,
            link,
            name: title,
            type: 'Goggle',
            realId: id,
            realLink: link,
          })
        }
        style={{ cursor: 'pointer' }}
      >
        {isAdvertising && (
          <span style={{ color: '#000000', fontWeight: 'bold' }}>
            Реклама ·
          </span>
        )}{' '}
        <span style={{ color: '#000000' }}>{decorLink}</span>{' '}
        <span style={{ fontSize: 14, lineHeight: 1.58 }}>{doubleTitle}</span>
      </span>
      <span style={{ cursor: 'pointer' }}>
        <h2
          onClick={() =>
            requestLink({
              id,
              link,
              name: title,
              type: 'Goggle',
              realId: id,
              realLink: link,
            })
          }
        >
          {title}
        </h2>
      </span>
      <p>{desc}</p>
    </div>
  )
}

export default CardGoogle
