import React from 'react'
import { Randomizer } from '../../../helpers/randomizer'
import { BlockHeader } from '../components/block-header'
import ReactApexChart from 'react-apexcharts'
import { Button } from '../../../components/button'
import { Card } from './card'
import { ModalPaid } from '../components/modal-paid'
import { requestTraffics } from '../../../request/hooks/api'

type Props = {
  isBindCard: boolean
  lastName: string
  middleName: string
  firstName: string
}

const RatingTab = (props: Props) => {
  const [rating, setRating] = React.useState(0)

  React.useEffect(() => {
    // requestTraffics(`/profile/rating`, null)
  })

  const state = {
    series: [rating / 5],
    options: {
      chart: {
        height: 450,
        type: 'radialBar',
        toolbar: {
          show: true,
        },
      },
      //@ts-ignore
      chart: {
        toolbar: {
          show: false,
          tools: {
            download: false,
          },
        },
      },
      plotOptions: {
        radialBar: {
          startAngle: -135,
          endAngle: 225,
          hollow: {
            margin: 0,
            size: '60%',
            background: '#fff',
            image: undefined,
            imageOffsetX: 0,
            imageOffsetY: 0,
            position: 'front',
            dropShadow: {
              enabled: true,
              top: 3,
              left: 0,
              blur: 4,
              opacity: 0.24,
            },
          },
          track: {
            background: '#fff',
            strokeWidth: '67%',
            margin: 0, // margin is in pixels
            dropShadow: {
              enabled: true,
              top: -3,
              left: 0,
              blur: 4,
              opacity: 0.35,
            },
          },

          dataLabels: {
            show: true,
            name: {
              offsetY: -10,
              show: true,
              color: '#888',
              fontSize: '24px',
            },
            value: {
              formatter: function (val: any) {
                return val * 5
              },
              color: '#111',
              fontSize: '36px',
              show: true,
            },
          },
        },
      },
      fill: {
        type: 'gradient',
        gradient: {
          shade: 'dark',
          type: 'horizontal',
          shadeIntensity: 0.5,
          gradientToColors: ['#ABE5A1'],
          inverseColors: true,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 100],
        },
      },
      stroke: {
        lineCap: 'round',
      },
      labels: ['Рейтинг'],
    },
  }

  const [openModal, setOpenModal] = React.useState(false)

  const handleClose = () => {
    setOpenModal(false)
  }

  return (
    <div className={'tab-rating'}>
      <ModalPaid
        user={{
          lastName: props.lastName,
          middleName: props.middleName,
          firstName: props.firstName,
        }}
        open={openModal}
        handleClose={handleClose}
      />
      <BlockHeader
        title={'Проверьте свой кредитный рейтинг'}
        text={'рейтинг рассчитан по собственной модели moneytka'}
        isButton={false}
        icon={'speed'}
      />
      <div className={'tab-rating__block'}>
        <ReactApexChart
          options={state.options as any}
          series={state.series}
          type="radialBar"
        />
        <div className={'tab-rating__button'}>
          <span>Узнать кредитный рейтинг</span>
          <Button
            onClick={() => {
              if (!props.isBindCard) {
                return setOpenModal(true)
              }
              const value = JSON.parse(localStorage.getItem('rating') as any)
              if (value) {
                return setRating(
                  JSON.parse(localStorage.getItem('rating') as any)
                )
              } else {
                const valueRating = Randomizer(350, 600)
                setRating(valueRating)
                localStorage.setItem('rating', valueRating)
              }
            }}
            title={'Узнать кредитный рейтинг'}
            theme={'yellow'}
          />
        </div>
      </div>
      <div className={'tab-rating__block-result'}>
        <Card
          title={'До 200'}
          text={`Очень низкая оценка. В государственных банках вы получите отказ в кредите. Займ вы сможете получить лишь с помощью МФО, КПК и ломбардов.`}
        />
        <Card
          title={'200-300'}
          text={
            'Вероятность отказа высокая. Вы сможете надеяться на одобрение дорого кредита определенной категории (например, товарный кредит) на небольшой срок.'
          }
        />
        <Card
          title={'300-400'}
          text={
            'С такой оценкой вы можете рассчитывать на кредит. Однако условия по нему будет диктовать сам банк. Есть большая вероятность снижения одобренной суммы и повышение процента по займу.'
          }
        />
        <Card
          title={'400-500'}
          text={
            'Стандартный балл. Данный рейтинг позволяет оформить кредит на стандартных условиях.'
          }
        />
        <Card
          title={'От 500'}
          text={
            'Самая высокая оценка финансового здоровья. С такими баллами уже вы сами выбираете банки и подходящие для вас условия кредитования. Также вы можете требовать понижение ставки по займу.'
          }
        />
      </div>
    </div>
  )
}

export default RatingTab
