import React from 'react'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { styleTab, tabs } from '../conts/tabs'

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

export const TabsComponent = ({
  activeTab,
  changeActiveTab,
}: {
  changeActiveTab: (event: React.SyntheticEvent, newValue: number) => void
  activeTab: number
}) => {
  return (
    <Tabs
      value={activeTab}
      onChange={changeActiveTab}
      aria-label="basic tabs example"
      className={'block-tabs'}
    >
      {tabs
        .filter(tab => tab.isDesktop)
        .map(tab => (
          <Tab
            key={tab.index}
            label={tab.title}
            sx={styleTab}
            {...a11yProps(tab.index)}
          />
        ))}
    </Tabs>
  )
}
