import classNames from 'classnames'
import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { UserContext } from '../../../context/auth'
import { requestPayment, requestRegister } from '../../../request/hooks/api'
import './style.scss'

function getRnd(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1)) + min
}

const StepFaceLoader = (props: any) => {
  const { setAuth }: any = useContext(UserContext)
  const [status, setStatus] = React.useState({
    personal: false,
    passport: false,
    fssp: false,
    mvd: false,
  })
  const [isRegister, setIsRegister] = React.useState(0)

  const navigate = useNavigate()
  const time = { max: 10000, min: 7000 }

  React.useEffect(() => {
    setTimeout(() => {
      setStatus(value => ({ ...value, personal: true }))
    }, getRnd(time.min, time.max))
    setTimeout(() => {
      setStatus(value => ({ ...value, mvd: true }))
    }, getRnd(time.min, time.max))
    setTimeout(() => {
      setStatus(value => ({ ...value, passport: true }))
    }, getRnd(time.min, time.max))
    setTimeout(() => {
      setStatus(value => ({ ...value, fssp: true }))
    }, getRnd(time.min, time.max))
  })

  React.useEffect(() => {
    if (isRegister === 1) {
      requestRegister({ ...props.state })
        .then(response => response.data)
        .then(data => {
          localStorage.setItem('token', String(data.token.token))
          localStorage.setItem('refreshToken', String(data.token.refreshToken))
          localStorage.setItem('userInfo', JSON.stringify(data.user))
          setAuth(true)
          // @ts-ignore
          ym(67359901, 'reachGoal', 'register')
          //@ts-ignore
          ym(93059549, 'reachGoal', 'go_to_payment')
          if (localStorage.getItem('payment') && !data.user.isBindCard) {
            setTimeout(() => requestPayment(), 300)
          }
          return navigate('/profile/auth')
        })
        .catch(error => {
          props.setActiveStep(2)
          props.setErrorForm(error.response.data.error.request)
        })
    }
  }, [isRegister])

  React.useEffect(() => {
    if (status.fssp && status.mvd && status.passport && status.passport) {
      setIsRegister(prev => prev + 1)
    }
  }, [status])

  return (
    <div className={'face-loader'}>
      <img
        className={classNames('face-loader__images')}
        src={'/assets/large-circle.png'}
      />
      <div className={'face-loader__title'}>
        Проверяем ваши
        <br /> данные
      </div>
      <div
        className={classNames('', {
          'face-loader__elem-list-active': status.personal,
          'face-loader__elem-list': !status.personal,
        })}
      >
        Контактная информация
      </div>
      <div
        className={classNames('', {
          'face-loader__elem-list-active': status.passport,
          'face-loader__elem-list': !status.passport,
        })}
      >
        Паспортные данные
      </div>
      <div
        className={classNames('', {
          'face-loader__elem-list-active': status.fssp,
          'face-loader__elem-list': !status.fssp,
        })}
      >
        Проверка в базе ФССП
      </div>
      <div
        className={classNames('', {
          'face-loader__elem-list-active': status.mvd,
          'face-loader__elem-list': !status.mvd,
        })}
      >
        Проверка в базе МВД
      </div>
    </div>
  )
}
export default StepFaceLoader
