import React from 'react'
type Props = {
  title: string
  icon: 'bad' | 'ok'
}
export const CardVerification = (props: Props) => {
  return (
    <div className={'tab-verification__card'}>
      <div>
        <img src={`/assets/icons/${props.icon}.svg`} />
      </div>
      <span>{props.title}</span>
    </div>
  )
}
