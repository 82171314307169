import React from 'react'
import { BASE_URL_ICON, tabs } from '../conts/tabs'

type Tab = {
  index: number
  title: string
  iconActive: string
  icon: string
  isMobile: boolean
  isDesktop: boolean
  activeTab: number
  setActiveTab: (index: number) => void
}

const Tab = (tab: Tab) => (
  <div
    onClick={() => tab.setActiveTab(tab.index)}
    className={'mobile-menu__menu'}
  >
    <img
      src={
        tab.activeTab === tab.index
          ? BASE_URL_ICON + tab.iconActive
          : BASE_URL_ICON + tab.icon
      }
    />
    <div>{tab.title}</div>
  </div>
)

export const TabsMobileComponent = ({
  activeTab,
  setActiveTab,
}: {
  activeTab: number
  setActiveTab: (index: number) => void
}) => {
  return (
    <div className={'mobile-menu'}>
      {tabs
        .filter(tab => tab.isMobile)
        .map(tab => (
          <Tab
            key={tab.index}
            {...tab}
            setActiveTab={setActiveTab}
            activeTab={activeTab}
          />
        ))}
    </div>
  )
}
