import React from 'react'
import { Document } from './document'
import { documents } from '../../../static-data/documents'
import './style.scss'
import { AddedAdsFin } from '../../../helpers/add-adsFin'
import { requestTraffics } from '../../../request/hooks/api'

const DocumentsPage = () => {
  React.useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    setTimeout(AddedAdsFin(), 1000)
    // requestTraffics('/documents', null)
  }, [])

  console.log('hello documents')
  return (
    <div className={'container documents'}>
      <div className={'container__row'}>
        <div className={'documents__block-title'}>Документы “Монетка”</div>

        <div id="doc-1" style={{ marginTop: 20 }}>
          <ins id="place_110659760776262115"></ins>
        </div>
        <div className={'documents__block-doc'} style={{ marginBottom: 20 }}>
          {localStorage.getItem('ref') === '1'
            ? documents
                .filter(item => item.id !== 3 && item.id !== 5)
                .map(doc => (
                  <Document key={doc.id} title={doc.title} link={doc.link} />
                ))
            : documents.map(doc => (
                <Document key={doc.id} title={doc.title} link={doc.link} />
              ))}
        </div>
        <div id={'doc-2'}>
          <ins id="place_110659760776262115"></ins>
        </div>
      </div>
    </div>
  )
}

export default DocumentsPage
