import React from 'react'
import CloseIcon from '@mui/icons-material/Close'
import { useRequestAxios } from '../request/hooks/use-request-axios'
import { Randomizer } from '../helpers/randomizer'

export const AlertComponent = () => {
  const [isOpen, setIsOpen] = React.useState<Boolean>(false)
  const url = new URL(document.URL)
  // console.log('hello --- url', url.pathname.indexOf('analytics'))
  function redirectTraffic(link: string) {
    setTimeout(() => {
      window.open(link, '_blank')
    }, 100)
    setIsOpen(false)
  }

  React.useState(() => {
    setTimeout(() => setIsOpen(true), 5000)
  })
  const { data, errors }: { data: any; errors: any } = useRequestAxios({
    url: `showcases?type=${50}`,
    statusUrl: false,
  })

  const [offer, setOffer] = React.useState<any>(null)

  React.useEffect(() => {
    if (data) {
      setOffer(data?.offers?.[Randomizer(0, data?.offers.length - 1)])
    }
  }, [data])

  if (
    isOpen &&
    offer &&
    url.pathname.indexOf('analytics') === -1 &&
    url.pathname.indexOf('register') === -1
  ) {
    return (
      <div id={'custom_alert'} className={'custom-alert'}>
        <div onClick={() => setIsOpen(false)} className={'custom-alert__close'}>
          <CloseIcon />
        </div>
        <img
          onClick={() => {
            redirectTraffic(offer?.link)
          }}
          src={'https://moneytka.ru/' + offer?.offer?.image?.file?.url}
          className={'custom-alert__img'}
        />
        <div
          onClick={() => {
            redirectTraffic(offer?.link)
          }}
          className={'custom-alert__text'}
        >
          {offer?.offer?.description}
        </div>
      </div>
    )
  }
  return <div></div>
}
