import React from 'react'

type Props = {
  title: string
  fields: { label: string; value: string }[]
}

export const BlockInfo = (props: Props) => {
  return (
    <div className={'tab-profile__block'}>
      <div className={'tab-profile__block__title'}>{props.title}</div>
      <div className={'tab-profile__block__cards'}>
        {props.fields.map((field, key) => (
          <div key={key} className={'tab-profile__block__cards__card'}>
            <span className={'tab-profile__block__cards__card__label'}>
              {field.label}
            </span>
            <div className={'tab-profile__block__cards__card__value'}>
              {field.value}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
