import React from 'react'
import './style.scss'
type Props = {
  title: string
  text1: string
  text2: string
  image: string
}

const Article1Page = (props: any) => {
  React.useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])
  return (
    <div className={'container articles'}>
      <div className={'container__row'}>
        <div className={'articles__title'}>История развития МФО</div>
        <div className={'articles__text'}>
          <p>
            Считается, что в первые микрозаймы появились в Бангладеше. В 1974
            году профессор экономики Мухаммад Юнус вернулся в свой родную
            небольшую деревню и застал там голод. Многие его сограждане еле
            сводили концы с концами, им не хватало денег даже на еду. Тогда,
            Юнус решил давать в долг всем, кто хотел наладить свой бизнес. Суммы
            были небольшие, всего пара десятков долларов под небольшие проценты.
            Многие смеялись над идеей профессора, но вскоре все увидели, как те,
            кому он давал небольшой займ, вставали на ноги и возвращали взятые
            деньги с процентами. Уже в 1983 году Юнус основал банк Grameen. Он
            предназначался для микрокредитования бедных слоев населения, потому
            что крупные банковские организации не хотели выдавать мелкие суммы,
            и видели в этом риск невозврата.
          </p>
          <p>
            Деятельность банка была очень успешной, за что в 2006 году
            профессору вручили Нобелевскую премию мира за развитие теории о
            микрокредитовании как возможность для бедных людей стать
            предпринимателями.
          </p>
        </div>
        <div
          className={'articles__block-img'}
          style={{ display: 'flex', flexDirection: 'row' }}
        >
          <div>
            <img src={'/assets/articles/1.png'} />
          </div>
          <div className={'articles__text'}>
            <h4>Как это было в России?</h4>
            <p>
              МФО, которые мы привыкли видеть сейчас, появились в России в
              середине 90-ых. Тогда было тяжелое время для наших сограждан:
              госпредприятия месяцами не выдавали зарплату, и люди пытались
              заработать деньги другими способами, открывая свой небольшой
              бизнес. А для его развития и прочие бытовые нужды нужны были
              деньги. Суммы обычно требовались скромные, поэтому банки не хотели
              с этим сталкиваться. Так и появились организации, дающие небольшие
              деньги в долг.
            </p>
            <h4>Легализация МФО в РФ</h4>
            <p>
              У нас в стране долго не существовало законодательной базы для
              микрофинансирования. Обе стороны - и заемщик, и заимодавец, -
              очень сильно рисковали.Бизнес стал легализованным лишь с
              появлением соответствующего закона № 151-ФЗ от 02.07.2010 «О
              микрофинансовой деятельности и микрофинансовых организациях». В
              документе указаны разные аспекты: от требований к учредителям
              компании до прав и обязанностей заемщика.
            </p>
          </div>
        </div>
        <div className={'articles__text'}>
          <h4>Этот законопроект сыграл важную роль:</h4>
          <p>
            • Помог избавиться от криминализированных структур;
            <br />
            • Обязал МФО давать полную информацию о размере и сроках погашения
            долга;
            <br />
            • Дал гарантию на возможность досрочно погасить займ;
            <br />
            • Установил максимальные размеры процентов.
            <br />
          </p>
          <p>
            В 2011 году утвердили порядок ведения государственного реестра МФО.
            В список включаются только те компании, которые соблюдают требования
            закона. С полным перечнем организаций можно ознакомиться на
            официальном сайте Банка России.
          </p>

          <h4>
            В 2015 году в ФЗ внесли поправки, согласно которым с 2017 года все
            МФО делятся на два типа:
          </h4>
          <p>
            1. Микрофинансовые компании – в них вы можете оформить займ до
            миллиона рублей. <br />
            2. Микрокредитные компании – выдают кредиты до пятисот тысяч рублей.
          </p>
        </div>
        <div className={'articles__text'}>
          <h4>Что будет дальше?</h4>
          <p>
            В январе прошлого года вступили в силу еще одни корректировки в
            законодательстве о микрофинансировании. Они подразумевают уменьшение
            максимального размера выплат, а также ограничивают ежедневный
            процент. Это сделает микрокредитование более выгодным для
            потребителей.
          </p>
          <p>
            Также ужесточение закона позволит избавиться от слабых компании. На
            рынке останутся только самые крупные и надежные организации, которые
            сейчас развивают направление онлайн-кредитования. Такая система
            займов позволит заемщикам не выходя из дома получить на карту деньги
            в долг, всего лишь заполнив анкету на сайте. Так, микрозаймы станут
            доступными не только для жителей крупных городов, но и любого уголка
            нашей необъятной страны.
          </p>
        </div>
      </div>
    </div>
  )
}

export default Article1Page
