import * as React from 'react'
import { alpha } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import { visuallyHidden } from '@mui/utils'

interface Data {
  name: string | null
  leads: number | null
  users: number | null
  valid: number | null
  sentPostbacks: number | null
  withheldPostbacks: number | null
  linkedCards: number | null
  activeCards: number | null
  recurringSum: number
}

export function createData(
  name: string | null,
  leads: number | null,
  users: number | null,
  valid: number | null,
  sentPostbacks: number | null,
  withheldPostbacks: number | null,
  linkedCards: number | null,
  activeCards: number | null,
  recurringSum: number
): Data {
  return {
    name,
    leads,
    users,
    valid,
    sentPostbacks,
    withheldPostbacks,
    linkedCards,
    activeCards,
    recurringSum,
  }
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

type Order = 'asc' | 'desc'

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) {
      return order
    }
    return a[1] - b[1]
  })
  return stabilizedThis.map(el => el[0])
}

interface HeadCell {
  disablePadding: boolean
  id: keyof Data
  label: string
  numeric: boolean
}

const headCells: readonly HeadCell[] = [
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Партнер',
  },
  {
    id: 'leads',
    numeric: true,
    disablePadding: true,
    label: 'Переходы на сайт',
  },
  {
    id: 'users',
    numeric: true,
    disablePadding: true,
    label: 'Регистрации',
  },
  {
    id: 'valid',
    numeric: true,
    disablePadding: true,
    label: 'Валидные',
  },
  {
    id: 'sentPostbacks',
    numeric: true,
    disablePadding: true,
    label: 'Отправлено постбеков',
  },
  {
    id: 'withheldPostbacks',
    numeric: true,
    disablePadding: true,
    label: 'Удержано постбеков',
  },
  {
    id: 'linkedCards',
    numeric: true,
    disablePadding: true,
    label: 'Привязано карт',
  },
  {
    id: 'activeCards',
    numeric: true,
    disablePadding: true,
    label: 'Активных карт',
  },
  {
    id: 'recurringSum',
    numeric: true,
    disablePadding: false,
    label: 'Сумма списаний',
  },
]

interface EnhancedTableProps {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => void
  order: Order
  orderBy: string
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort } = props
  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property)
    }

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            sx={{ fontWeight: '600' }}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

interface EnhancedTableToolbarProps {
  title: string
}

function EnhancedTableToolbar(props: EnhancedTableToolbarProps) {
  const { title } = props

  return (
    <Toolbar>
      <Typography
        sx={{ flex: '1 1 100%' }}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        {title}
      </Typography>
    </Toolbar>
  )
}

export default function EnhancedTable({
  title,
  rows,
}: {
  title: string
  rows: any
}) {
  const [order, setOrder] = React.useState<Order>('asc')
  const [orderBy, setOrderBy] = React.useState<keyof Data>('users')
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)

  const handleRequestSort = (property: keyof Data) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <EnhancedTableToolbar title={title} />
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={'medium'}
          >
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort as any}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.name}
                    >
                      <TableCell component="th" scope="row">
                        {row.name}
                      </TableCell>
                      <TableCell align="center">{row.leads}</TableCell>
                      <TableCell align="center">{row.users}</TableCell>
                      <TableCell align="center">{row.valid}</TableCell>
                      <TableCell align="center">{row.sentPostbacks}</TableCell>
                      <TableCell align="center">
                        {row.withheldPostbacks}
                      </TableCell>
                      <TableCell align="center">{row.linkedCards}</TableCell>
                      <TableCell align="center">{row.activeCards}</TableCell>
                      <TableCell align="center">{row.recurringSum}</TableCell>
                    </TableRow>
                  )
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  )
}
