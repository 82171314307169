import React from 'react'
import { useNavigate } from 'react-router'
import './style.scss'

const Article = ({
  title,
  desc,
  date,
  link,
}: {
  title: string
  desc: string
  date: string
  link: string
}) => {
  const navigate = useNavigate()
  return (
    <div className={'article'} onClick={() => navigate(link)}>
      <div className={'article__title'}>{title}</div>
      <div className={'article__text'}>{desc}</div>
      <div className={'article__date'}> {date}</div>
    </div>
  )
}
export default Article
