import React, { useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { AddedAdsFin } from '../../helpers/add-adsFin'
import './style.scss'

const Footer = () => {
  const params = useLocation()

  useEffect(() => {
    setTimeout(AddedAdsFin(), 1000)
  }, [])
  if (params.pathname === '/google-page') {
    return <div></div>
  }

  return (
    <React.Fragment>
      <div id={'about_1'}>
        {params.pathname !== '/google-page' ? (
          <ins id="place_110657996012009115"></ins>
        ) : (
          <div />
        )}
      </div>
      <footer className={'container'}>
        <div className={'container__row'}>
          <footer
            className={'footer footer__border'}
            style={{ backgroundColor: 'white' }}
          >
            <div className={'footer__header'}>
              <div className={'footer__header__image'}>
                <img src={'/assets/logo.svg'} />
              </div>
              <div className={'footer__header__menu'}>
                <div>
                  <Link to={'/about'}> О сервисе</Link>
                </div>
                <div>
                  <Link to={'/register'}>Оформить займ</Link>
                </div>
                <div>
                  <Link to={'/login'}>Отменить подписку</Link>
                </div>
                <div>
                  <Link to={'/documents'}>Документы</Link>
                </div>
                <div>
                  <Link to={'/contacts'}> Контакты</Link>
                </div>
              </div>
            </div>
          </footer>
          <footer className={'footer'} style={{ backgroundColor: 'white' }}>
            <div className={'footer__about'}>
              <div className={'footer__about__card'}>
                <img src={'/assets/footer/email.svg'} />
                <span>
                  По всем вопросам обращаться на почту:
                  <br /> <b>client@moneytka.ru</b>
                </span>
              </div>
              <div className={'footer__about__card'}>
                <img src={'/assets/footer/calendar.svg'} />
                <span>
                  Режим работы поддержки с 8 до 18:00 по мск, сб вс выходные
                  <br />
                  Телефон : <b>+7(499) 719 3607</b>
                </span>
              </div>
              <div className={'footer__about__card'}>
                <img src={'/assets/footer/location.svg'} />
                <span>
                  Россия, Алтайский край, <br />
                  <b>г.Новоалтайск, ул. Анатолия, д. 41В</b>
                </span>
              </div>
              <div className={'footer__about__card'}>
                <img src={'/assets/footer/protection.svg'} />
                <span>
                  <b>Все данные зашифрованы по стандарту DES.</b>
                  <br />
                  Сервис осуществляет подбор микрозаймов между лицом, желающим
                  оформить займ, и кредитными учреждениями.
                </span>
              </div>
            </div>
          </footer>

          <footer
            className={'footer footer__text-block '}
            style={{ paddingTop: 0 }}
          >
            <div className={'footer__text '}>
              <span>
                ИП Лобова Е.Ю. ОГРНИП 321220200218420 ИНН 220804108563 658080,
                Алтайский край, г.Новоалтайск, ул. Анатолия, 41В Мы являемся
                операторами персональных данных{' '}
                <a
                  href={
                    'https://pd.rkn.gov.ru/operators-registry/operators-list/?id=22-21-003136'
                  }
                  target={'_blank'}
                >
                  <b>(Номер 22-21-003136, Приказ № 104-нд от 24.05.2021)</b>
                </a>{' '}
                Сервис предназначен для лиц старше 18 лет. Подписка на услугу
                обработки заявки является платной и составляет 1999 рублей в
                месяц. Наличие платной подписки не гарантирует Вам получение
                займа. Применяются рекуррентные платежи. Стоимость услуг
                регулируется действующими тарифами. Если услуга перестала быть
                актуальной для Вас (Вы получили займ или более не нуждаетесь в
                займах), Вы можете самостоятельно отписаться от услуги в любой
                момент -{' '}
                <Link to={'/login'}>
                  <b>отменить подписку</b>
                </Link>
                . Сумма списаний не может превышать 1999 рублей в течение
                календарного месяца. Находясь на сайте, а также совершая любые
                действия на сайте, Вы принимаете условия договора публичной
                оферты, политику обработки персональных данных, ознакомлены с
                приказом и тарифами сервиса, а также даете согласие на получение
                рекламных материалов и на обработку персональных данных. Сервис
                Монетка использует куки для предоставления услуг. Условия
                хранения или доступа к куки Вы можете настроить в своём
                браузере. Режим работы службы поддержки: с 8 до 18:00 по мск, сб
                вс выходные. По всем интересующим вопросам Вы можете обратиться
                на e-mail службы поддержки сервиса client@moneytka.ru или по
                телефону +7(499) 719 3607
              </span>
              <p>Пример расчета онлайн-ссуды:</p>
              <span>
                Если вы взяли ссуду на сумму 30 000 рублей на 3 месяца с
                процентной ставкой 4,5% в месяц, то после истечения срока ссуды
                переплата составит 4050 рублей . Получается вы возвращаете 34050
                рублей. Ниже приведены расчеты начисляемых процентов для
                дальнейшего самостоятельного вычисления суммы возврата ссуды (с
                процентом) пользователям среднего сервиса. Сумма - 30 000 рублей
                , процент в месяц - 4,5%, срок ссуды - 3 месяца. 4,5(%) * 3(мес)
                = 13,5% за 3 месяца(13,5% * 30 000 рублей (сумма ссуды) )/100% =
                4050 рублей (сумма начисленных процентов) 30000 + 4050 = 34050
                рублей - это сумма необходимых денег к возврату через 3 месяца.
                В случае нарушения сроков, размер неустойки составляет 0,10% от
                суммы просрочки в день, но не более 10% от суммы ссуды. В случае
                длительной задержки выплаты информация будет передана в БКИ.
                Продление ссуды возможно при своевременном информировании
                кредитора (до наступления даты возврата займа) и уплаты
                процентов за первичный срок ссуды. Дополнительных комиссий за
                продление ссуды не предусмотрено.
              </span>
              <p>Что будет при невыплате ссудных средств?</p>
              <span>
                В случае невозвращения в условленный срок суммы ссуды или суммы
                процентов за пользование ссудными средствами кредитор вынуждено
                начислит штраф за просрочку платежа. Они предусмотрены на
                случай, к примеру, если банковский перевод занял больше времени,
                чем обычно. Однако, в случае неполучения от Вас какой-либо
                реакции в течение продолжительного времени, будет начислен штраф
                за просрочку срока погашения размером в среднем 0,10% от
                первоначальной суммы для ссуды, 0,03% от суммы задолженности в
                среднем для потребительских кредитов и кредитных карт. При
                несоблюдении Вами условий по погашению кредитов, данные о Вас
                могут быть переданы в реестр должников или БКИ, что негативно
                может сказаться на Вашей кредитной истории и рейтинге
                кредитоспособности. Задолженность может быть передана
                коллекторскому агентству для взыскания долга. При допуске
                просрочки продление ссуды невозможно. Погашая задолженность в
                срок, Вы формируете хорошую кредитную историю, что повышает Ваш
                рейтинг кредитоспособности и шансы в дальнейшем получить кредит
                на более выгодных условиях. Список кредиторов{' '}
              </span>
              <div className={'footer__cookies '}>
                <p>Список кредиторов:</p>
                <span>
                  ООО &quot;Микрокредитная компания универсального
                  финансирования&quot;, 344101, г. Ростов-на-Дону, ул.
                  Ленинградская, д. 7, каб. 11Б, Лицензия № 001503760007126, ИНН
                  6162070130, ОГРН 1156196059997, процентная ставка от 0,99 % в
                  день <br /> ООО МКК &quot;ПЛИСКОВ&quot;, 410052, г. Саратов,
                  просп. им. 50 Лет Октября, дом 132А, офис 33, Лицензия №
                  1903363009056, ИНН 6453158170, ОГРН 1186451026288, процентная
                  ставка от 0,1 % в день
                  <br /> ООО МКК &quot;Кредиттер&quot;, 115280, г. Москва, ул.
                  Ленинская слобода, д. 19, стр. 6, ком. 7, Лицензия №
                  1903045009373, ИНН 7702463482, ОГРН 1197746100530, процентная
                  ставка от 0,99 % в день
                  <br /> ООО &quot;МКК КАНГАРИЯ&quot;, 344101, г.
                  Ростов-на-Дону, ул. Ленинградская, д. 7, пом. № П-13, Лицензия
                  № 1904067009295, ИНН 9201526872, ОГРН 1189204008564,
                  процентная ставка от 0,99 % в день <br /> ООО МФК
                  &quot;ДЗП-Центр&quot;, 190031, г. Санкт-Петербург, пер.
                  Спасский, д. 14/35, лит. А, пом. 38 Н, Лицензия №
                  651403140005467, ИНН 7838500558, ОГРН 1147847029990,
                  процентная ставка от 0 % в день <br /> ООО МФК
                  &quot;ЦФП&quot;, 117218, г. Москва, ул. Кржижановского, д. 29,
                  корп. 5, пом. I, ком. 10, Лицензия № 2110177000840, ИНН
                  7733783309, ОГРН 1117746890645, процентная ставка от 0 % в
                  день
                  <br /> ООО МКК &quot;КировФинанс&quot;, 610020, г. Киров, ул.
                  Карла Маркса, д. 10, оф. 37, Лицензия № 651303333002855, ИНН
                  4345343687, ОГРН 1124345026222, процентная ставка от 0 % в
                  день
                  <br /> ООО МФК &quot;Займер, 630099, г. Новосибирск, ул.
                  Октябрьская магистраль, д. 3, оф. 906, Лицензия №
                  651303532004088, ИНН 4205271785, ОГРН 1134205019189,
                  процентная ставка от 0 % в день
                  <br /> ООО МКК &quot;Киберлэндинг&quot;, 420043, г. Казань,
                  ул. Достоевского, д. 52, оф. 3, Лицензия № 1803392008777, ИНН
                  1659182700, ОГРН 1171690064920, процентная ставка от 0 % в
                  день
                  <br /> ООО МКК &quot;КАППАДОКИЯ&quot;, 443045, г. Самара, ул.
                  Авроры, д. 150, пом. №н4, офис 306.3, Лицензия №
                  1903475009492, ИНН 7459007268, ОГРН 1197456052199, процентная
                  ставка от 0 % в день <br />
                  OОО МК &quot;ЮПИТЕР 6&quot;, 199397, г. Санкт-Петербург, ул.
                  Кораблестроителей, д. 32, к.1, литера А, пом. 38Н, ком. 9,
                  Лицензия № 2110178000979, ИНН 7840460408, ОГРН 1117847472973,
                  процентная ставка от 0 % в день
                  <br /> ООО МКК &quot;КОНГА&quot;, 664047, г. Иркутск, ул.
                  Карла Либкнехта 121, оф. 507, Лицензия № 001603550007495, ИНН
                  5407264020, ОГРН 1155476135110, процентная ставка от 0,5 % в
                  день
                  <br /> ООО МФК &quot;Займ Онлайн&quot;, 127083, г. Москва, ул.
                  8 Марта, д. 1, стр. 12, этаж 10, пом. LVI, ком. 2-7, Лицензия
                  № 2120177001838, ИНН 7703769314, ОГРН 1127746428171,
                  процентная ставка от 0 % в день
                </span>
              </div>
              <div className={'footer__cookies '}>
                <span>
                  * - Заполняя данное поле , Вы даете согласие на проверку
                  номера телефона на действительность и принадлежность именно
                  Вам, путем получения проверочного кода, а так же даете
                  согласие на получение рекламных материалов-смс рассылок.
                </span>
              </div>
            </div>
            <div className={'footer__cookies '}>
              <span>
                Для повышения удобства работы с сайтом Монетка{' '}
                <b
                  style={{
                    color: 'var(--color-button-green)',
                    cursor: 'pointer',
                  }}
                >
                  использует файлы cookie
                </b>
                . В cookie содержатся данные о прошлых посещениях сайта. Если вы
                не хотите, чтобы эти данные обрабатывались, отключите cookie в
                настройках браузера.
              </span>
            </div>
          </footer>
        </div>
      </footer>
    </React.Fragment>
  )
}

export default Footer
