import React, { useCallback } from 'react'
import env from '../../env'

type Props = { url: string; method?: string; params?: any }

export const useRequest = ({ url, method = 'GET', params }: Props) => {
  const [data, setData] = React.useState(null)
  const [loader, setLoader] = React.useState(false)
  const [errors, setError] = React.useState(null)

  React.useEffect(() => {
    search(params)
  }, [])

  const clearData = useCallback(() => {
    setData(null)
  }, [])

  const search = (newParams?: any) => {
    setLoader(true)
    setError(null)
    fetch(env.baseUrl + url + '?' + newParams, {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
      .then(res => res.json())
      .then(
        result => setData(result),
        error => setError(error)
      )
      .finally(() => setLoader(false))
  }

  return { data, search, loader, clearData, errors }
}
