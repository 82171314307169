import React from 'react'
import Slider, { SliderTrack } from '@mui/material/Slider'
import './style.scss'

type Props = {
  step: number
  marks: { value: number }[]
  setValue: (value: number) => void
  max: number
  min: number
  defaultValue: number
}

export const SliderCustom = (props: Props) => {
  const { marks, setValue, max, min, defaultValue, step } = props

  //@ts-ignore
  function valuetext(value) {
    return `${value}`
  }
  return (
    <Slider
      defaultValue={defaultValue}
      step={step}
      marks={marks}
      max={max}
      min={min}
      classes={{
        valueLabelCircle: 'slider__circle',
        root: 'slider__root',
      }}
      // track={false}
      //@ts-ignore
      onChange={event => setValue(event.target.value)}
      valueLabelDisplay="off"
    />
  )
}
