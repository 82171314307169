import axios from 'axios'
import React from 'react'
import { User } from '..'
import { Button } from '../../../components/button'
import { requestFeedback, requestTraffics } from '../../../request/hooks/api'
import { sendMessageComplain } from '../../../request/hooks/tg'

const ComplaintTab = (props: User) => {
  const [text, setText] = React.useState('')
  const [isSend, setSend] = React.useState(false)

  const handleSubmit = () => {
    sendMessageComplain({
      phone: props.phone,
      email: props.email,
      lastName: props.lastName,
      firstName: props.firstName,
      middleName: props.middleName,
      text: text,
    })

    requestFeedback({
      name: props.lastName + ' ' + props.firstName,
      email: props.email,
      phone: props.phone,
      text,
    }).then(() => setSend(true))
  }
  React.useEffect(() => {
    requestTraffics(`/profile/complaint`, null)
  })
  return (
    <div className={'tab-complaint'}>
      <div className={'text-title'}>
        Есть вопросы? Напишите нам, и мы поможем вам!
      </div>
      {isSend ? (
        <div className={'tab-complaint__send-block'}>
          <span>
            Спасибо за обращение!
            <br />
            Ваше сообщение успешно отправлено!
            <br />
            Мы свяжемся с вами в ближайшее время.
          </span>
        </div>
      ) : (
        <div>
          <div>
            <span className={'tab-complaint__label'}>
              Текст вашего обращения
            </span>
          </div>
          <textarea
            //@ts-ignore
            onInput={e => setText(e.target?.value)}
            className={'tab-complaint__textarea'}
          />
          <div className={'tab-complaint__button'}>
            <Button
              title={'Отправить сообщение'}
              onClick={() => handleSubmit()}
              theme={'yellow'}
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default ComplaintTab
