import * as React from 'react'

import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

import RequestTab from './request'
import VerificationTab from './verification'
import RatingTab from './rating'
import ProfileTab from './profile'
import ComplaintTab from './complaint'
import UnsubscribeTab from './unsubscribe'
import { TabsComponent } from './components/tabs'
import { TabsMobileComponent } from './components/mobile-tabs'

import './style.scss'

export type User = {
  birthday: string
  email: string
  firstName: string
  gender: 0 | 1
  isBindCard: boolean
  lastName: string
  localAddress: string
  localCity: string
  localRegion: string
  middleName: string
  passportDepartmentCode: string
  passportGiveout: string
  passportGiveoutDate: string
  passportNumber: string
  passportSeries: string
  phone: string
  registrationAddress: string
  registrationCity: string
  registrationFlat: string
  registrationHouse: string
  registrationRegion: string
  registrationStreet: string
  validate: {
    emailApproved: boolean
    fioApproved: boolean
    passportApproved: boolean
    phoneApproved: boolean
  }
}
interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

const ProfilePage = () => {
  const [value, setValue] = React.useState(0)
  const [user, setUser] = React.useState<User>(
    JSON.parse(localStorage.getItem('userInfo') as any)
  )

  React.useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  return (
    <div className={'container profile'}>
      <div className={'container__row'}>
        <div className={'text-title'}>Личный кабинет</div>
        <TabsComponent changeActiveTab={handleChange} activeTab={value} />
        <TabsMobileComponent activeTab={value} setActiveTab={setValue} />
        <TabPanel value={value} index={0}>
          <RequestTab
            firstName={user.firstName}
            lastName={user.lastName}
            middleName={user.middleName}
            isBindCard={user.isBindCard}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <RatingTab
            firstName={user.firstName}
            lastName={user.lastName}
            middleName={user.middleName}
            isBindCard={user.isBindCard}
          />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <ProfileTab user={user} />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <VerificationTab isBindCard={user.isBindCard} {...user.validate} />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <ComplaintTab {...user} />
        </TabPanel>
        <TabPanel value={value} index={5}>
          <UnsubscribeTab
            isBindCard={user.isBindCard}
            firstName={user.firstName}
            lastName={user.lastName}
            middleName={user.middleName}
            gender={user.gender}
            setUser={setUser}
          />
        </TabPanel>
      </div>
    </div>
  )
}

export default ProfilePage
