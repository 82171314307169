import React from 'react'
import './style.scss'
import { Button } from '../../../components/button'

type Props = {
  title: string
  text?: string
  isButton: boolean
  icon: 'ok' | 'bad' | 'speed'
  onClick?: () => void
}

export const BlockHeader = ({
  title,
  isButton,
  icon,
  text,
  onClick,
}: Props) => {
  return (
    <div className={'block-header'}>
      <div className={'block-header__block-text'}>
        <img src={`/assets/icons/${icon}.svg`} />
        <div>
          {title}
          <span>{text}</span>
        </div>
      </div>
      {isButton && (
        <div className={'block-header__block-button'}>
          <Button
            title={'Все предложения'}
            onClick={onClick as any}
            theme={'yellow'}
          />
        </div>
      )}
    </div>
  )
}
