import React, { useEffect, useLayoutEffect } from 'react'
import { AddedAdsFin } from '../../../helpers/add-adsFin'
import { requestTraffics } from '../../../request/hooks/api'
import AboutHome from '../home/components/about'
import Questions from '../home/components/questions'
import { convenience } from '../home/data'
import './style.scss'

const AboutPage = () => {
  React.useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])

  useEffect(() => {
    setTimeout(AddedAdsFin(), 1000)
    // requestTraffics('/about', null)
  }, [])

  return (
    <div className={'container about'}>
      <div className={'container__row'}>
        <div className={'about__title'}>О сервисе “Монетка”</div>
        <div className={'about__text'}>
          В наше время, когда рынок онлайн кредитования в России стремительно
          развивается, наш сервис является одним из самых удобных и прозрачных
          для клиента, предлагающий своим клиентам привлекательные условия для
          поиска заимствования денежных средств.
          <br />
          <br /> Широкие функциональные возможности сайта за счет наличия
          собственных внутренних алгоритмов скоринга позволяют персонализировать
          подбор кредитных продуктов. Кроме того, Вы имеете возможность доступа
          к средствам посредством различных способов, включая переводы на
          карточку или счет, зачисление средств на различные электронные
          кошельки и так далее.
          <br /> <br />
          Наш клиент может оформить заявку на заем круглосуточно, вне
          зависимости от дня недели, при этом затраченное время не превысит 20
          минут. Сервис Moneytka предлагает доступ к деньгам в любое время, что
          существенно расширяет возможности человека по решению финансовых
          проблем.
        </div>
        <div style={{ marginTop: 30 }} id={'about-2'}>
          <ins id="place_110659760776262115"></ins>
        </div>
        <AboutHome title={''} data={convenience} />
        <Questions />
        <div id={'about-1'}>
          <ins id="place_110659760776262115"></ins>
        </div>
      </div>
    </div>
  )
}

export default AboutPage
