import React, { useLayoutEffect } from 'react'
import { useNavigate } from 'react-router'
import { Button } from '../../../components/button'
import { AddedAdsFin } from '../../../helpers/add-adsFin'
import { requestTraffics } from '../../../request/hooks/api'
import AboutHome from '../home/components/about'
import { subscription } from '../home/data'
import './style.scss'

const SubscriptionPage = () => {
  const navigate = useNavigate()
  React.useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    setTimeout(AddedAdsFin(), 1000)
    // requestTraffics('/subscriptions', null)
  }, [])

  return (
    <>
      <div
        className={'subscription'}
        style={{
          backgroundImage: ' url("/assets/background/background.png")',
          backgroundRepeat: 'no-repeat',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <div className={'subscription__left'}>
          <img src="/assets/tik.svg" />
        </div>

        <div className={'subscription__right'}>
          <img src="/assets/tik.svg" />
        </div>
        <div className={'subscription__row '}>
          <div className={'subscription__row__images'}>
            <img src={'/assets/moneytka.svg'} />
          </div>
          <div className={'subscription__row__text'}>
            Наличие подписки включает множество полезных услуг и функций
            недоступных обычным пользователям
          </div>
          <div className={'subscription__row__button'}>
            <Button
              theme={'yellow'}
              title={'Подписаться'}
              onClick={() => navigate('/login')}
            />
          </div>
          <div className={'subscription__row__helpers'}>
            <div>
              <img src={'/assets/ok-white.svg'} />
              Подписка на 30 дней
            </div>
            <div>
              <img src={'/assets/ok-white.svg'} />
              Оплата в рассрочку
            </div>
          </div>
        </div>
      </div>
      <div className={'container about'}>
        <div className={'container__row'}>
          <div id={'1'}>
            <ins id="place_110659760776262115"></ins>
          </div>
          <AboutHome data={subscription} title={'Что дает подписка?'} />
          <div id={'123'}>
            <ins id="place_110659760776262115"></ins>
          </div>
        </div>
      </div>
    </>
  )
}

export default SubscriptionPage
