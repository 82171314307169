import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@mui/material'
import React from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

type Props = {
  id: string
  title: string
  text: string
}

export const AccordionCustom = (props: Props) => {
  return (
    <Accordion
      style={{
        borderRadius: 20,
        border: '1px solid rgba(18, 18, 18, 0.05)',
        boxShadow: '0px 18px 52.8537px rgba(215, 228, 249, 0.1)',
      }}
      sx={{ maxWidth: 570, marginBottom: 2 }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={props.id}
        id={props.id}
      >
        <Typography className={'accordion__title'}>{props.title}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography>{props.text}</Typography>
      </AccordionDetails>
    </Accordion>
  )
}
