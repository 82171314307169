import { Checkbox, CircularProgress, FormControlLabel } from '@mui/material'
import { format } from 'date-fns'
import React from 'react'
import { Button } from '../../../components/button'
import { documents } from '../../../static-data/documents'

const BlockInfo = ({ label, value }: { label: string; value: string }) => (
  <div className={'block-info'} style={{ display: 'flex', marginBottom: 5 }}>
    <div style={{ fontSize: 14, fontWeight: '600' }}>{label} : </div>
    <div style={{ paddingLeft: 10, fontSize: 14 }}>{value}</div>
  </div>
)

const StepLast = (props: any) => {
  const status = localStorage.getItem('status_payment')

  React.useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])
  const [checked, setChecked] = React.useState(status === 'true' ? true : false)

  return (
    <div className={'personal-data'}>
      <div className={'personal-data__block-title'}>
        <div className={'personal-data__block-title__title'}>
          Проверьте данные
        </div>
        <div
          className={'personal-data__block-title__desc'}
          style={{ paddingBottom: 0 }}
        >
          <BlockInfo
            label={'ФИО'}
            value={
              props?.state?.lastName +
                ' ' +
                props?.state?.firstName +
                ' ' +
                props?.state?.middleName ||
              '' ||
              '-'
            }
          />
          <BlockInfo
            label={'Пол'}
            value={props?.state?.sex === 'women' ? 'Женский' : 'Мужской'}
          />
          <BlockInfo
            label={'Дата рождения'}
            value={
              props?.state?.birthday
                ? format(new Date(props?.state?.birthday), 'dd.MM.yyyy')
                : '-'
            }
          />
          <BlockInfo label={'Телефон'} value={props?.state?.phone || '-'} />
          <BlockInfo label={'Email'} value={props?.state?.email || '-'} />
          <BlockInfo
            label={'Город проживания'}
            value={props?.state?.city || '-'}
          />
          <BlockInfo
            label={'Серия и номер паспорта'}
            value={props?.state?.pasportNumber || '-'}
          />
          <BlockInfo
            label={'Код подразделения'}
            value={props?.state?.passportDepartmentCode || '-'}
          />
          <BlockInfo
            label={'Дата выдачи'}
            value={
              props?.state?.passportGiveoutDate
                ? format(
                    new Date(props?.state?.passportGiveoutDate),
                    'dd.MM.yyyy'
                  )
                : '-'
            }
          />
          <BlockInfo
            label={'Адрес прописки'}
            value={props?.state?.registrationAddress?.value || '-'}
          />
          <FormControlLabel
            label={''}
            style={{ marginTop: 10, marginLeft: 0, marginRight: 0 }}
            control={
              <>
                <Checkbox
                  onChange={e => setChecked(e.target.checked)}
                  color={'success'}
                  checked={checked}
                />
                <div
                  style={{
                    fontSize: '12px',
                    textAlign: 'justify',
                    height: status === 'true' ? 74 : 'auto',
                    overflowY: status === 'true' ? 'scroll' : 'hidden',
                  }}
                >
                  Даю согласие на{' '}
                  <a
                    href={documents[4].link}
                    className={'na-text-weight-bold'}
                    target={'_blank'}
                  >
                    <i>
                      <b> обработку персональных данных</b>
                    </i>
                  </a>{' '}
                  и получение{' '}
                  <a
                    href={documents[0].link}
                    className={'na-text-weight-bold'}
                    target={'_blank'}
                  >
                    <i>
                      <b>
                        {' '}
                        материалов рекламного характера (Email и SMS рассылка)
                      </b>
                    </i>
                  </a>{' '}
                  . Все данные зашифрованы по стандарту DES. Для повышения
                  удобства работы с сайтом Монетка использует файлы cookie. В
                  cookie содержатся данные о прошлых посещениях сайта. Если вы
                  не хотите, чтобы эти данные обрабатывались, отключите cookie в
                  настройках браузера.
                  {String(localStorage.getItem('payment')) === 'true' &&
                  localStorage.getItem('ref') !== '1'
                    ? ` Для активации сервиса будет списано один рубль,
              дальнейшее использование сервиса будет проходить в
              соответствии `
                    : 'Для активации сервиса с вашей карты будет списан один рубль,который будет сразу возвращен обратно на ту же карту. Таким способом мы проверим Вашу карту на платежеспособность и принадлежность именно Вам.'}
                  {String(localStorage.getItem('payment')) === 'true' &&
                    localStorage.getItem('ref') !== '1' && (
                      <a href={documents[3].link} target={'_blank'}>
                        <b>Тарифам</b>
                      </a>
                    )}
                  {String(localStorage.getItem('payment')) === 'true' &&
                    localStorage.getItem('ref') !== '1' &&
                    ` Общая сумма списания за месяц не может превышать более одной тесячи девятьсот девяносто девяти рублей`}{' '}
                  , а так же я согласен с{' '}
                  <a
                    href={documents[2].link}
                    className={'na-text-weight-bold'}
                    target={'_blank'}
                  >
                    <i>
                      <b>договором публичной оферты</b>
                    </i>
                  </a>
                  {localStorage.getItem('ref') !== '1' && ' и '}
                  {localStorage.getItem('ref') !== '1' && (
                    <a
                      href={documents[5].link}
                      className={'na-text-weight-bold'}
                      target={'_blank'}
                    >
                      <i>
                        <b>рекуррентными платежами</b>
                      </i>
                    </a>
                  )}
                </div>
              </>
            }
          ></FormControlLabel>
        </div>
      </div>
      <div>
        {/* {loader ? (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <CircularProgress color={'success'} />
          </div>
        ) : ( */}
        <div
          className={'personal-data__button-block'}
          style={{ marginBottom: 20 }}
        >
          <Button
            title={'Подтвердить'}
            onClick={() => props.setActiveStep((pre: number) => pre + 1)}
            theme={'dark'}
            disabled={!checked}
          />
          <Button
            title={'Назад'}
            theme={'gray'}
            onClick={() => props.setActiveStep((pre: number) => pre - 1)}
          />
        </div>
        {/* )} */}
      </div>
    </div>
  )
}

export default StepLast
