import React from 'react'
import { AccordionCustom } from '../../../../components/accordion'
import { questionsAnswers } from '../data'
import { Button } from '../../../../components/button'
import { useNavigate } from 'react-router-dom'

const Questions = () => {
  const navigate = useNavigate()
  return (
    <div className={''}>
      <div className={'home__questions '}>
        <img
          className={'home__questions__img-1'}
          src={'/assets/background/question2.svg'}
        />
        <img
          className={'home__questions__img-3'}
          src={'/assets/background/circle.svg'}
        />
        <img
          className={'home__questions__img-2'}
          src={'/assets/background/question1.svg'}
        />
        <div className={'home__questions__title'}>
          <h1 className={'text-title'}>Часто задаваемые вопросы</h1>
        </div>
        <div className={'home__questions__accordion'}>
          {questionsAnswers.map(item => (
            <AccordionCustom
              key={item.id}
              title={item.title}
              text={item.text}
              id={`panel_${item.id}`}
            />
          ))}
        </div>
        <div className={'home__questions__button'}>
          <Button
            theme={'yellow'}
            title={'Задать свой вопрос'}
            onClick={() => navigate('/register')}
          />
        </div>
      </div>
    </div>
  )
}

export default Questions
