import React from 'react'
import Modal from '@mui/material/Modal'
import Backdrop from '@mui/material/Backdrop'
import Fade from '@mui/material/Fade'
import { Box, Typography } from '@mui/material'
import { Button } from '../../../components/button'
import {
  requestDeleteCardPayment,
  requestMe,
  requestPayment,
} from '../../../request/hooks/api'
import { useNavigate } from 'react-router-dom'
import { sendMessageIsBingCardUnsubscribe } from '../../../request/hooks/tg'

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: 700,
  bgcolor: 'background.paper',
  border: '1px solid rgba(18, 18, 18, 0.05)',
  boxShadow: '0px 18px 52.8537px rgba(215, 228, 249, 0.1)',
  borderRadius: '18px',
  p: 4,
}

type Props = {
  open: boolean
  handleClose: () => void
  user: {
    lastName: string
    firstName: string
    middleName: string
    gender: any
  }
  setUser: (value: any) => void
}

export const ModalUnsubscribe = ({
  open,
  handleClose,
  user,
  setUser,
}: Props) => {
  const [unsubscribe, setUnsubscribe] = React.useState(false)
  return (
    <Modal
      aria-labelledby="spring-modal-title"
      aria-describedby="spring-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open} className={'custom-modal'}>
        <Box sx={style}>
          <div
            className={'request-tab__block-not-card'}
            style={{ justifyContent: 'flex-start', alignItems: 'flex-start' }}
          >
            <div className={'request-tab__block-not-card__text'}>
              <div
                className={'request-tab__block-not-card__text__title'}
                style={{ textAlign: 'left', justifyContent: 'flex-start' }}
              >
                <img src={`/assets/icons/bad.svg`} />{' '}
                {unsubscribe ? 'Подписка отменена' : 'Отмена подписки'}
              </div>
              {unsubscribe ? (
                <div
                  className={'request-tab__block-not-card__text__text'}
                  style={{ textAlign: 'left' }}
                >
                  Уважаем{user.gender ? 'ый' : 'ая'}{' '}
                  <span>
                    {user.firstName} {user?.middleName} {user.lastName}
                  </span>
                  , вы успешно отписались от всех платных услуг.
                </div>
              ) : (
                <div
                  className={'request-tab__block-not-card__text__text'}
                  style={{ textAlign: 'left' }}
                >
                  Уважаем{user.gender ? 'ый' : 'ая'}{' '}
                  <span>
                    {user.firstName} {user?.middleName} {user.lastName}
                  </span>
                  , вы действительно хотите отменить подписку? Для доступа к
                  полному функционала личного кабинета необходимо иметь активную
                  подписку.
                </div>
              )}
            </div>
            {unsubscribe ? (
              <div
                className={'request-tab__block-not-card__buttons'}
                style={{
                  paddingBottom: 0,
                }}
              >
                <Button
                  theme={'dark'}
                  title={'Закрыть'}
                  onClick={handleClose}
                />
              </div>
            ) : (
              <div
                className={'request-tab__block-not-card__buttons'}
                style={{
                  paddingBottom: 0,
                }}
              >
                <Button
                  theme={'gray'}
                  title={'Отменить подписку'}
                  onClick={() => {
                    requestDeleteCardPayment().then(() =>
                      requestMe().then(response => {
                        setUnsubscribe(true)
                        sendMessageIsBingCardUnsubscribe()
                        localStorage.setItem('ref', '0')
                        setUser(response.data)
                        localStorage.setItem(
                          'userInfo',
                          JSON.stringify(response.data)
                        )
                      })
                    )
                  }}
                />
                <Button
                  theme={'dark'}
                  title={'Продолжить пользоваться'}
                  onClick={handleClose}
                />
              </div>
            )}
          </div>
        </Box>
      </Fade>
    </Modal>
  )
}
