import React, { useEffect } from 'react'
import { Button } from '../../../components/button'
import { Showcase } from '../../showcase/components/showcase'
import { useRequestAxios } from '../../../request/hooks/use-request-axios'
import AboutHome from '../../static-pages/home/components/about'
import { subscription } from '../../static-pages/home/data'
import { Randomizer } from '../../../helpers/randomizer'
import classNames from 'classnames'
import {
  requestDeleteCardPayment,
  requestMe,
  requestPayment,
} from '../../../request/hooks/api'
import { AddedAdsFin } from '../../../helpers/add-adsFin'
import { ModalUnsubscribe } from '../components/modal-unsabscribe'
import { sendMessageIsBingCardUnsubscribe } from '../../../request/hooks/tg'
import { useNavigate } from 'react-router-dom'

type Props = {
  isBindCard: boolean
  lastName: string
  firstName: string
  gender: any
  middleName: string
  setUser: (value: any) => void
}

const UnsubscribeTab = (props: Props) => {
  const { data }: { data: any } = useRequestAxios({
    url: `showcases?type=${
      props.isBindCard ? 20 : 10
    }&leadId=${localStorage.getItem('leadId')}`,
    statusUrl: props.isBindCard ? true : false,
  })

  const [countWatch, setCountWatch] = React.useState<number | null>(null)
  const [openModal, setOpenModal] = React.useState(false)

  React.useEffect(() => {
    if (Number(localStorage.getItem('count'))) {
      setCountWatch(Number(localStorage.getItem('count')))
    } else {
      const count = Randomizer(11, 30)
      setCountWatch(count)
      localStorage.setItem('count', count)
    }
  }, [])

  useEffect(() => {
    setTimeout(AddedAdsFin(), 1000)
  }, [])

  return (
    <div>
      <ModalUnsubscribe
        handleClose={() => setOpenModal(false)}
        open={openModal}
        setUser={props.setUser}
        user={{
          lastName: props.lastName,
          firstName: props.firstName,
          middleName: props.middleName,
          gender: props.gender,
        }}
      />
      <div className={'tab-unsubscribe'}>
        <div
          className={classNames('tab-unsubscribe__title', {
            'active-card': props.isBindCard,
            'not-active-card': !props.isBindCard,
          })}
        >
          <span>
            {props.isBindCard ? 'Подписка активна' : 'Подписка не активна'}
          </span>
        </div>
        <div className={'tab-unsubscribe__text-mfo'}>
          Количество рассмотренных заявок МФО <span>{countWatch}</span>
        </div>
        <div className={'tab-unsubscribe__button'}>
          <Button
            title={
              props.isBindCard ? 'Отменить подписку' : 'Подключить подписку'
            }
            theme={props.isBindCard ? 'gray' : 'dark'}
            onClick={() => {
              if (props.isBindCard) {
                return setOpenModal(true)
              }
              return requestPayment()
            }}
          />
        </div>
        <div className={'tab-request__showcase'}>
          <div className={'tab-request__showcase__cards'}>
            {data?.offers?.slice(0, 4)?.map((item: any) => (
              <Showcase key={item.id} {...item} />
            ))}
          </div>
        </div>
        <div id="profile-1" style={{ marginTop: 20 }}>
          <ins id="place_110659760776262115"></ins>
        </div>

        <AboutHome data={subscription} title={'Что дает подписка?'} />
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: 70,
            marginBottom: 70,
          }}
        >
          <Button
            theme={'dark'}
            title={'Пожертвовать на разработку'}
            onClick={() => {
              //@ts-ignore
              ym(67359901, 'reachGoal', 'donat')
              window.open('https://new.donatepay.ru/@1108965', '_blank')
            }}
          />
        </div>
        <div id="profile-1" style={{ marginTop: 20 }}>
          <ins id="place_110659760776262115"></ins>
        </div>
      </div>
    </div>
  )
}

export default UnsubscribeTab
