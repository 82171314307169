import React from 'react'
import Modal from '@mui/material/Modal'
import Backdrop from '@mui/material/Backdrop'
import Fade from '@mui/material/Fade'
import { Box, Typography } from '@mui/material'
import { Button } from '../../../components/button'
import { requestPayment } from '../../../request/hooks/api'

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: 700,
  bgcolor: 'background.paper',
  border: '1px solid rgba(18, 18, 18, 0.05)',
  boxShadow: '0px 18px 52.8537px rgba(215, 228, 249, 0.1)',
  borderRadius: '18px',
  p: 4,
}

type Props = {
  open: boolean
  handleClose: () => void
  user: {
    lastName: string
    firstName: string
    middleName: string
  }
}

export const ModalPaid = ({ open, handleClose, user }: Props) => {
  return (
    <Modal
      aria-labelledby="spring-modal-title"
      aria-describedby="spring-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open} className={'custom-modal'}>
        <Box sx={style}>
          <div
            className={'request-tab__block-not-card'}
            style={{ justifyContent: 'flex-start', alignItems: 'flex-start' }}
          >
            <div className={'request-tab__block-not-card__text'}>
              <div
                className={'request-tab__block-not-card__text__title'}
                style={{ textAlign: 'left', justifyContent: 'flex-start' }}
              >
                <img src={`/assets/icons/bad.svg`} /> Ограниченный доступ
              </div>
              <div
                className={'request-tab__block-not-card__text__text'}
                style={{ textAlign: 'left' }}
              >
                Уважаемый{' '}
                <span>
                  {user.firstName} {user?.middleName} {user.lastName}
                </span>
                , для просмотра лучших предложений от кредитных организаций
                необходимо, активировать доступ( подписку) к нашему сервису. Для
                активации сервиса с вашей карты будет списан 1 рубль, который в
                последующем вернется на вашу карту в течение 1-5 минут. Данная
                процедура является проверкой вашей карты на платежеспособность и
                принадлежности именно Вам, а также для предотвращения
                мошенничества со стороны недобросовестных клиентов.
              </div>
            </div>
            <div
              className={'request-tab__block-not-card__buttons'}
              style={{
                paddingBottom: 0,
              }}
            >
              <Button theme={'gray'} title={'Отменить'} onClick={handleClose} />
              <Button
                theme={'dark'}
                title={'Начать пользоваться'}
                onClick={() => requestPayment()}
              />
            </div>
          </div>
        </Box>
      </Fade>
    </Modal>
  )
}
