import React from 'react'

export const Card = ({ title, text }: { title: string; text: string }) => {
  return (
    <div className={'tab-rating__block-result__card'}>
      <div className={'tab-rating__block-result__card__title'}>{title}</div>
      <div className={'tab-rating__block-result__card__text'}>{text}</div>
    </div>
  )
}
