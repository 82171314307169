export function AddedAdsFin() {
  if (localStorage.getItem('status_adsfin') === 'true') {
    const script = document.createElement('script')
    script.src = 'https://front.adsfin.net/place/110657996012009115/'
    script.async = true
    document.body.appendChild(script)
    return () => {
      document.body.removeChild(script)
    }
  } else {
    return () => {}
  }
}
