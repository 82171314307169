import React, { useCallback, useContext, useEffect } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'

import { Button } from '../../components/button'
import HeaderMobile from './mobile'

import { UserContext } from '../../context/auth'
import { requestLead } from '../../request/hooks/api'
import { RefViewPageContext } from '../../context/referal-page'
import { getWatchPage } from '../../request/hooks/getWatchPage'
import { partners } from './partners'

import './style.scss'

const Header = ({ city }: { city: string }) => {
  const navigate = useNavigate()
  const { isAuth, setAuth }: any = useContext(UserContext)
  const { setViewPage }: any = useContext(RefViewPageContext)

  const requestLeadCallback = useCallback(
    (params: {
      partner: string | null
      webmaster: string | null
      transaction: string | null
      ref: boolean
    }) => {
      const { partner, webmaster, transaction, ref } = params
      requestLead(partner, webmaster, transaction, setViewPage)
        .then(data => {
          ref && localStorage.setItem('ref', '1')
          localStorage.setItem('leadId', data.data.id)
        })
        .finally(() => navigate('/'))
    },
    []
  )

  const applyForLoan = useCallback(() => {
    // @ts-ignore
    ym(93059549, 'reachGoal', 'click1_zaim')
    window.open('/register', '_blank')

    if (String(localStorage.getItem('showcase_google')) === 'true') {
      return navigate(
        `/google-page?search=${JSON.stringify('займы без отказа')}`
      )
    } else {
      navigate('/showcase', { state: { type: 10 } })
    }
  }, [])

  const exitProfile = useCallback(() => {
    setAuth(false)
    localStorage.removeItem('token')
    localStorage.removeItem('userInfo')
    return navigate('/')
  }, [])

  useEffect(() => {
    if (!localStorage.getItem('leadId')) {
      const url = new URL(document.URL)
      for (let i = 0; i < partners.length; i++) {
        const searchParams = url?.searchParams?.get(partners?.[i]?.searchParams)
        const { partner, webmaster, transaction, ref, source } = partners?.[i]
        if (source && searchParams === source) {
          requestLeadCallback({
            partner,
            webmaster,
            transaction,
            ref,
          })
          break
        } else if (source === null && searchParams) {
          requestLeadCallback({
            partner,
            webmaster,
            transaction,
            ref,
          })
          break
        }
      }
      if (!localStorage.getItem('leadId')) {
        requestLeadCallback({
          partner: null,
          webmaster: null,
          transaction: null,
          ref: true,
        })
      }
    } else {
      setViewPage(getWatchPage(Number(localStorage.getItem('statusWatchOpen'))))
    }
  }, [])

  const params = useLocation()

  if (params.pathname === '/google-page') {
    return <div />
  }
  return (
    <React.Fragment>
      <header className={'container'}>
        <div className={'container__row'}>
          <div style={{ background: 'white' }}>
            <div className={'header '}>
              <div className={'header__top'}>
                <div className={'header__top__block-location'}>
                  <div
                    className={'header__top__block-location__logo'}
                    onClick={() => navigate('/')}
                  >
                    <img
                      src={'/assets/logo.svg'}
                      style={{ cursor: 'pointer' }}
                    />
                  </div>
                  <div className={'header__top__block-location__location'}>
                    <img src={'/assets/location.svg'} /> {city}
                  </div>
                  <div
                    className={'header__top__block-location__chat'}
                    onClick={() =>
                      window.open(
                        'https://t.me/test_Kristina_22_bot?start=welcome'
                      )
                    }
                  >
                    <img src={'/assets/chat.svg'} /> Онлайн-чат
                  </div>
                </div>
                <div className={'header__top__block-button'}>
                  <Button
                    theme={'white'}
                    title={'Оформить займ'}
                    onClick={applyForLoan}
                  />
                  <Button
                    theme={'dark'}
                    title={isAuth ? 'Профиль' : 'Войти'}
                    style={{ marginLeft: 18 }}
                    onClick={() => navigate(isAuth ? '/profile' : '/login')}
                    iconButton={<img src={'/assets/login.svg'} />}
                  />
                  {isAuth && (
                    <Button
                      theme={'dark'}
                      title={'Выход'}
                      style={{ marginLeft: 18 }}
                      onClick={exitProfile}
                      iconButton={<img src={'/assets/login.svg'} />}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
          <div style={{ background: 'white', borderTop: '3px solid #fcfbfc' }}>
            <div className={'header'}>
              <div className={'header__bottom'}>
                <div className={'header__bottom__link'}>
                  <Link to={'/about'}>О сервисе</Link>
                </div>
                <div className={'header__bottom__link'}>
                  <Link to={'/register'}>Оформить займ</Link>
                </div>
                <div className={'header__bottom__link'}>
                  <Link to={'/subscription'}>Наша подписка</Link>
                </div>
                <div className={'header__bottom__link'}>
                  <Link to={'/documents'}>Документы</Link>
                </div>
                <div className={'header__bottom__link'}>
                  <Link to={'/contacts'}>Контакты</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <HeaderMobile />
    </React.Fragment>
  )
}

export default Header
