import React, { useEffect } from 'react'
import { AddedAdsFin } from '../../../helpers/add-adsFin'
import { requestTraffics } from '../../../request/hooks/api'
import { Contact } from './contact'
import './style.scss'

const ContactsPage = () => {
  useEffect(() => {
    setTimeout(AddedAdsFin(), 1000)
    // requestTraffics('/contact', null)
  })
  return (
    <div className={'container contacts'}>
      <div className={'container__row'}>
        <div className={'contacts__title'}>Наши контакты</div>
        <div className={'contacts__block-cards'}>
          <Contact
            title={'Адрес'}
            text={'Россия, Алтайский край, г.Новоалтайск, ул. Анатолия, д. 41В'}
          />
          <Contact
            title={'Режим работы'}
            text={'Режим работы поддержки с 8 до 18:00 по мск, сб вс выходные'}
          />
          <Contact
            title={'Телефон'}
            textHelper={'+7(499) 719 3607'}
            text={'(по этому номеру телефона нельзя оформить заявку)'}
          />
          <Contact
            title={'Почта'}
            textHelper={'client@moneytka.ru'}
            text={'По всем вопросам обращаться на электронную почту.'}
          />
        </div>
        <div className={'contacts__calc'}>{/* <BlockCalculate /> */}</div>
        <div id="doc-1" style={{ marginTop: 20 }}>
          <ins id="place_110659760776262115"></ins>
        </div>
      </div>
    </div>
  )
}

export default ContactsPage
