import React from 'react'
import Modal from '@mui/material/Modal'
import Backdrop from '@mui/material/Backdrop'
import Fade from '@mui/material/Fade'
import { Box } from '@mui/material'
import { Button } from '../../../components/button'
import { useNavigate } from 'react-router'
import { requestPayment } from '../../../request/hooks/api'

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: 700,
  bgcolor: 'background.paper',
  border: '1px solid rgba(18, 18, 18, 0.05)',
  boxShadow: '0px 18px 52.8537px rgba(215, 228, 249, 0.1)',
  borderRadius: '18px',
  p: 4,
}

type Props = {
  status: boolean
  open: boolean
  handleClose: () => void
  user: {
    lastName: string
    firstName: string
    middleName: string
  }
}

export const ModalPayment = ({ open, handleClose, user, status }: Props) => {
  const navigate = useNavigate()
  return (
    <Modal
      aria-labelledby="spring-modal-title"
      aria-describedby="spring-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open} className={'custom-modal'}>
        {status ? (
          <Box sx={style}>
            <div
              className={'request-tab__block-not-card'}
              style={{ justifyContent: 'flex-start', alignItems: 'flex-start' }}
            >
              <div className={'request-tab__block-not-card__text'}>
                <div
                  className={'request-tab__block-not-card__text__title'}
                  style={{ textAlign: 'left', justifyContent: 'flex-start' }}
                >
                  <img src={`/assets/icons/ok.svg`} /> Подписка активна!
                </div>
                <div
                  className={'request-tab__block-not-card__text__text'}
                  style={{ textAlign: 'left' }}
                >
                  Уважаемый{' '}
                  <span>
                    {user.firstName} {user?.middleName} {user.lastName}
                  </span>
                  , теперь Вам доступен полный функционал нашего сайта. Вам
                  предоставлена возможность обратиться в лучшие МФО и получить
                  желаемую сумму на карту.
                </div>
              </div>
              <div
                className={'request-tab__block-not-card__buttons'}
                style={{
                  paddingBottom: 0,
                }}
              >
                <Button
                  theme={'gray'}
                  title={'Отменить'}
                  onClick={handleClose}
                />
                <Button
                  theme={'dark'}
                  title={'Начать пользоваться'}
                  onClick={() => navigate('/showcase', { state: { type: 20 } })}
                />
              </div>
            </div>
          </Box>
        ) : (
          <Box sx={style}>
            <div
              className={'request-tab__block-not-card'}
              style={{ justifyContent: 'flex-start', alignItems: 'flex-start' }}
            >
              <div className={'request-tab__block-not-card__text'}>
                <div
                  className={'request-tab__block-not-card__text__title'}
                  style={{ textAlign: 'left', justifyContent: 'flex-start' }}
                >
                  <img src={`/assets/icons/bad.svg`} /> Ошибка активации
                  подписки
                </div>
                <div
                  className={'request-tab__block-not-card__text__text'}
                  style={{ textAlign: 'left' }}
                >
                  Во время оплаты произошла ошибка. Возможно вы указали
                  ошибочные данные при оплате или на вашем счете недостаточно
                  средств (менее 5 рублей) для списания. Проверьте данную
                  информацию и, пожалуйста, попробуйте оплатить еще раз.
                </div>
              </div>
              <div
                className={'request-tab__block-not-card__buttons'}
                style={{
                  paddingBottom: 0,
                }}
              >
                <Button
                  theme={'gray'}
                  title={'Отменить'}
                  onClick={handleClose}
                />
                <Button
                  theme={'dark'}
                  title={'Повторить попытку'}
                  onClick={() => requestPayment()}
                />
              </div>
            </div>
          </Box>
        )}
      </Fade>
    </Modal>
  )
}
