import axios from 'axios'
import env from '../../env'
import { format } from 'date-fns'
import { getWatchPage } from './getWatchPage'

const baseURLNotToken = () =>
  axios.create({
    baseURL: env.baseUrl,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Access-Control-Allow-Origin': '*',
      origin: '*',
      'Access-Control-Allow-Credentials': true,
    },
  })

const baseURL = () =>
  axios.create({
    baseURL: env.baseUrl,
    headers: {
      'Content-Type': 'application/json',
      Token: localStorage.getItem('token'),
      Accept: 'application/json',
    },
  })

export const requestAuth = ({
  phone,
  password,
}: {
  phone: string
  password: string
}) => {
  const phoneClear = '+' + `${phone.toString().replace(/[^\d]/g, '')}`
  return baseURLNotToken().post(`/api/users/tokens`, {
    phone: phoneClear,
    password: password,
    leadId: localStorage.getItem('leadId') || null,
  })
}

export const confirmedPhone = (phone: string) => {
  return baseURLNotToken().post('/api/verificationCodes/phone', {
    key: `${phone.toString().replace(/[^\d]/g, '')}`,
  })
}

export const confirmedCode = (props: { code: string; phone: string }) => {
  return baseURLNotToken().put('/api/verificationCodes/phone', {
    key: `${props.phone.toString().replace(/[^\d]/g, '')}`,
    code: `${props.code.toString().replace(/[^\d]/g, '')}`,
    leadId: Number(localStorage.getItem('leadId')) || null,
  })
}

export const decodeLink = (props: { link: string | null }) => {
  return baseURLNotToken().post('/api/links/decode', {
    shortLink: props.link,
  })
}

export const requestRegister = (props: any) => {
  const phone = `+${props.phone.toString().replace(/[^\d]/g, '')}`
  return baseURLNotToken().post('/api/users', {
    email: props.email.toLowerCase(),
    firstName: props.firstName,
    lastName: props.lastName,
    middleName: props.middleName,
    birthday: format(new Date(props.birthday), 'yyyy-MM-dd'),
    phone: phone.trim(),
    gender: props.sex === 'men' ? 1 : 2,
    passportSeries: props.pasportNumber.split('/')[0].trim(),
    passportNumber: props.pasportNumber.split('/')[1].trim(),
    passportDepartmentCode: props.passportDepartmentCode.trim(),
    passportGiveoutDate: format(
      new Date(props.passportGiveoutDate),
      'yyyy-MM-dd'
    ),
    localCity: ' ',
    localRegion: ' ',
    passportGiveout: props.passportGiveout,
    leadId: Number(localStorage.getItem('leadId')),
    localAddress: props.city,
    registrationAddress: props.registrationAddress.value,
    registrationRegion: props.registrationAddress.region_with_type,
    registrationCity: props.registrationAddress.city_with_type,
    registrationStreet: props.registrationAddress.street_with_type,
    registrationHouse:
      props.registrationAddress.house_type +
      ' ' +
      props.registrationAddress.house,
    registrationFlat:
      props.registrationAddress.flat_type +
      ' ' +
      props.registrationAddress.flat_type,
    creditAmount: props.sum,
    creditTerm: props.loanTerm,
  })
}

export const requestMe = () => {
  return baseURL().get('/api/users/me')
}

export const requestLead = (
  partner: any,
  webmaster: any,
  transaction: any,
  setViewPage?: any
) => {
  if (
    partner === 'leads' ||
    partner === 'adsfin' ||
    partner === 'leadcraft' ||
    partner === 'adsfinpro'
  ) {
    setTimeout(() => setViewPage && setViewPage(getWatchPage(1)), 1000)
  } else {
    setTimeout(() => setViewPage && setViewPage(getWatchPage(2)), 1000)
  }
  return baseURLNotToken().post(`/api/leads`, {
    partner,
    webmaster,
    transaction,
  })
}

export const requestTraffics = (link: any, operationHash: any) => {
  return baseURLNotToken()
    .post(`/api/traffics`, {
      link,
      operationHash,
      leadId: Number(localStorage.getItem('leadId')),
    })
    .catch(() =>
      setTimeout(
        () =>
          baseURLNotToken().post(`/api/traffics`, {
            link,
            operationHash,
            leadId: Number(localStorage.getItem('leadId')),
          }),
        1000
      )
    )
}

export const requestPayment = () => {
  return baseURL()
    .post(`/api/payments/cards`, {})
    .then(link => (window.location.href = link.data.formUrl))
}

export const requestCheckPayment = (transactionHash: any) => {
  return baseURL().post(`/api/payments/cards/${transactionHash}`)
}

export const requestDeleteCardPayment = () => {
  return baseURL().delete(`/api/payments/cards`, {})
}

export const requestVerificationCodes = (email: any) => {
  const data = { key: email }
  return baseURLNotToken()
    .post(`/api/verificationCodes/email`, data)
    .then(key => {
      console.log(key.data.key)
    })
}
export const requestReloadPassword = (token: any) => {
  return baseURLNotToken().put(`/api/users/passwords`, { token })
}

export const requestFeedback = ({
  name,
  phone,
  email,
  text,
}: {
  name: string
  phone: string
  email: string
  text: string
}) => {
  const phoneClear = '+' + `${phone.toString().replace(/[^\d]/g, '')}`
  return baseURLNotToken().post(`/api/forms/1`, {
    name,
    phone: phoneClear,
    email,
    text,
  })
}

export const requestPaymentStorage = () => {
  return baseURLNotToken()
    .get('/api/storage/payment')
    .then(data => {
      localStorage.setItem('payment', data.data.enable)
    })
}
export const requestRefferalStorage = () => {
  return baseURLNotToken().get('/api/storage/refferal')
}
export const requestShowcaseGoogleStorage = () => {
  return baseURLNotToken()
    .get('/api/storage/showcase_google')
    .then(data => {
      localStorage.setItem('showcase_google', data.data.showcase)
    })
}

export const requestAdsFinStatus = () => {
  return baseURLNotToken()
    .get('/api/storage/is_adsfin')
    .then(data => {
      localStorage.setItem('status_adsfin', data.data.status)
    })
}
export const requestRenderPayment = () => {
  return baseURLNotToken()
    .get('/api/storage/checkPayment')
    .then(data => {
      localStorage.setItem('status_payment', data.data.status)
    })
}
export const requestIsWatchPage = () => {
  return baseURLNotToken()
    .get('/api/storage/is_watch_home_page_info')
    .then(data => {
      localStorage.setItem(
        'isWatchHomePage',
        data.data.status === true ? '1' : '0'
      )
    })
}
