import React from 'react'

//1 - Не показывать содержимое
//2 - Показывать содержимое
//0 - Значение не определено

export const getWatchPage = (value?: number) => {
  if (Number(localStorage.getItem('isWatchHomePage')) === 1) {
    localStorage.setItem('statusWatchOpen', String(value))
    return value
  } else {
    localStorage.setItem('statusWatchOpen', String(2))
    return 2
  }
}
