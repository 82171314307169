import React from 'react'
import { User } from '..'
import { BlockHeader } from '../components/block-header'
import { BlockInfo } from './components/block'
import { format } from 'date-fns'
import { ru } from 'date-fns/locale'
import { requestTraffics } from '../../../request/hooks/api'

const ProfileTab = (props: { user: User }) => {
  const { user } = props
  React.useEffect(() => {
    // requestTraffics(`/profile/user-data`, null)
  })
  return (
    <div className={'tab-profile'}>
      <BlockHeader
        title={'Верификация не пройдена'}
        text={'Пожалуйста пройдите верификацию для полного одобрения'}
        isButton={false}
        icon={
          Object.entries(user.validate).filter(item => item[1] === false).length
            ? 'bad'
            : 'ok'
        }
      />
      <div className={'text-title'}>Персональные данные</div>
      <div>
        <BlockInfo
          title={'Личные данные'}
          fields={[
            { label: 'Фамилия', value: user.lastName },
            { label: 'Имя', value: user.firstName },
            { label: 'Отчество', value: user.middleName },
            {
              label: 'День рождения',
              value: format(new Date(user.birthday), 'dd.MM.yyyy', {
                locale: ru,
              }),
            },
          ]}
        />
        <BlockInfo
          title={'Контакты'}
          fields={[
            { label: 'Телефон', value: user.phone },
            { label: 'Почта', value: user.email },
          ]}
        />
        <BlockInfo
          title={'Паспортные данные'}
          fields={[
            { label: 'Пол', value: user.gender ? 'Мужской' : 'Женский' },
            {
              label: 'Серия и номер паспорта',
              value: user.passportSeries + ' ' + user.passportNumber,
            },
            { label: 'Код подразделения', value: user.passportDepartmentCode },
            {
              label: 'Дата выдачи паспорта',
              value: format(new Date(user.passportGiveoutDate), 'dd.MM.yyyy', {
                locale: ru,
              }),
            },
          ]}
        />
        <BlockInfo
          title={'Место проживания'}
          fields={[
            { label: 'Адрес проживания', value: user.registrationCity },
            { label: 'Адрес регистрации', value: user.registrationAddress },
          ]}
        />
      </div>
    </div>
  )
}

export default ProfileTab
