import { subscription } from './../../static-pages/home/data'
export const styleTab = {
  fontStyle: 'normal',
  fontWeight: '600',
  fontSize: 16,
  color: 'black',
  textTransform: 'none',
}

export const BASE_URL_ICON = '/assets/menu-mobile/'

const RATING = 'Рейтинг'
const PROFILE = 'Профиль'
const TASK = 'Заявка'
const VERIFICATION_DATA = 'Верификация данных'
const COMPLAINT = 'Жалобы'
const SUBSCRIPTION = 'Подписка'

export const tabs = [
  {
    index: 0,
    title: TASK,
    iconActive: 'rating-active.svg',
    icon: 'rating.svg',
    isMobile: false,
    isDesktop: true,
  },
  {
    index: 1,
    title: RATING,
    iconActive: 'rating-active.svg',
    icon: 'rating.svg',
    isMobile: true,
    isDesktop: true,
  },
  {
    index: 2,
    title: PROFILE,
    iconActive: 'profile-active.svg',
    icon: 'profile.svg',
    isMobile: true,
    isDesktop: true,
  },
  {
    index: 3,
    title: VERIFICATION_DATA,
    iconActive: '',
    icon: '',
    isMobile: false,
    isDesktop: true,
  },
  {
    index: 4,
    title: COMPLAINT,
    iconActive: 'complaint-active.svg',
    icon: 'complaint.svg',
    isMobile: true,
    isDesktop: true,
  },
  {
    index: 5,
    title: SUBSCRIPTION,
    iconActive: 'many-active.svg',
    icon: 'many.svg',
    isMobile: true,
    isDesktop: true,
  },
]
