import { Button, ButtonGroup, CircularProgress } from '@mui/material'
import React from 'react'
import './style.scss'
import EnhancedTable, { createData } from '../components/tableRegister'
import ShowcaseTable, { createDataShowcase } from '../components/tableShowcase'
import { useRequest } from '../../request/hooks/use-request'
import { General } from './type'
import {
  format,
  formatISO9075,
  startOfMonth,
  startOfDay,
  startOfYear,
  startOfYesterday,
} from 'date-fns'

const YESTERDAY = 'yesterday'
const TODAY = 'today'
const MONTH = 'month'
const YEAR = 'year'

const Dashboard = () => {
  const [filter, setFilter] = React.useState(TODAY)

  const {
    data,
    search,
  }: {
    data: General | null
    search: (newParams: string) => void

    errors: any
    clearData: () => void
  } = useRequest({
    url: '/api/statistics/general',
    method: 'GET',
    params: `dateFrom=${format(
      new Date().setHours(0, 0, 0, 0),
      'yyyy-MM-dd HH:mm:ss'
    )}&dateTo=${format(
      new Date().setHours(23, 59, 59, 59),
      'yyyy-MM-dd HH:mm:ss'
    )}`,
  })
  const straight: any = data?.['Прямой']
  const guruleads = data?.['guruleads']
  const leadcraft: any = data?.['leadcraft']
  const leadgid = data?.['leadgid']
  const leads: any = data?.['leads']
  const adsfin: any = data?.['adsfin']
  const yandex: any = data?.['yandex']
  const target: any = data?.['target']
  const vk_ads: any = data?.['vk_ads']

  if (!data) {
    return (
      <div
        style={{
          height: '80vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
        }}
      >
        <CircularProgress />
      </div>
    )
  }
  console.log(
    format(startOfMonth(new Date()).setHours(0, 0, 0, 0), 'yyyy-MM-dd hh:mm:ss')
  )
  return (
    <div className={'dashboard'}>
      <div className={'dashboard__filter'}>
        <ButtonGroup
          variant="contained"
          aria-label="outlined primary button group"
        >
          <Button
            onClick={() => {
              search(
                `dateFrom=${format(
                  new Date().setHours(0, 0, 0, 0),
                  'yyyy-MM-dd HH:mm:ss'
                )}&dateTo=${format(
                  new Date().setHours(23, 59, 59, 59),
                  'yyyy-MM-dd HH:mm:ss'
                )}`
              )
              setFilter(TODAY)
            }}
            color={filter === TODAY ? 'success' : 'primary'}
          >
            Сегодня
          </Button>
          <Button
            onClick={() => {
              setFilter(YESTERDAY)
              search(
                `dateFrom=${format(
                  startOfYesterday().setHours(0, 0, 0, 0),
                  'yyyy-MM-dd HH:mm:ss'
                )}&dateTo=${format(
                  startOfYesterday().setHours(23, 59, 59, 59),
                  'yyyy-MM-dd HH:mm:ss'
                )}`
              )
            }}
            color={filter === YESTERDAY ? 'success' : 'primary'}
          >
            Вчера
          </Button>
          <Button
            onClick={() => {
              search(
                `dateFrom=${format(
                  startOfMonth(new Date()).setHours(0, 0, 0, 0),
                  'yyyy-MM-dd HH:mm:ss'
                )}&dateTo=${format(
                  new Date().setHours(23, 59, 59, 59),
                  'yyyy-MM-dd HH:mm:ss'
                )}`
              )
              setFilter(MONTH)
            }}
            color={filter === MONTH ? 'success' : 'primary'}
          >
            Месяц
          </Button>
          <Button
            onClick={() => {
              search(
                `dateFrom=${format(
                  startOfYear(new Date()).setHours(0, 0, 0, 0),
                  'yyyy-MM-dd HH:mm:ss'
                )}&dateTo=${format(
                  new Date().setHours(23, 59, 59, 59),
                  'yyyy-MM-dd HH:mm:ss'
                )}`
              )
              setFilter(YEAR)
            }}
            color={filter === YEAR ? 'success' : 'primary'}
          >
            Год
          </Button>
        </ButtonGroup>
      </div>
      {/* <Cards {...(data as any)} /> */}
      <div style={{ marginTop: 20 }}>
        <EnhancedTable
          title={'Регистрации'}
          rows={[
            createData(
              'VK',
              vk_ads?.leads,
              vk_ads?.users,
              Number(vk_ads?.sentPostbacks) + Number(vk_ads?.withheldPostbacks),
              vk_ads?.sentPostbacks,
              vk_ads?.withheldPostbacks,
              vk_ads?.linkedCards,
              vk_ads?.activeCards,
              vk_ads?.recurringSum
            ),
            createData(
              'Прямые',
              straight?.leads,
              straight?.users,
              Number(straight?.sentPostbacks) +
                Number(straight?.withheldPostbacks),
              straight?.sentPostbacks,
              straight?.withheldPostbacks,
              straight?.linkedCards,
              straight?.activeCards,
              straight?.recurringSum
            ),
            createData(
              'Yandex',
              yandex?.leads,
              yandex?.users,
              Number(yandex?.sentPostbacks) + Number(yandex?.withheldPostbacks),
              yandex?.sentPostbacks,
              yandex?.withheldPostbacks,
              yandex?.linkedCards,
              yandex?.activeCards,
              yandex?.recurringSum
            ),

            createData(
              'Target',
              target?.leads,
              target?.users,
              Number(yandex?.sentPostbacks) + Number(target?.withheldPostbacks),
              target?.sentPostbacks,
              target?.withheldPostbacks,
              target?.linkedCards,
              target?.activeCards,
              target?.recurringSum
            ),
            createData(
              'Leads',
              leads?.leads,
              leads?.users,
              Number(leads?.sentPostbacks) + Number(leads?.withheldPostbacks),
              leads?.sentPostbacks,
              leads?.withheldPostbacks,
              leads?.linkedCards,
              leads?.activeCards,
              leads?.recurringSum
            ),
            createData(
              'LeadsCraft',
              leadcraft?.leads,
              leadcraft?.users,
              Number(leadcraft?.sentPostbacks) +
                Number(leadcraft?.withheldPostbacks),
              leadcraft?.sentPostbacks,
              leadcraft?.withheldPostbacks,
              leadcraft?.linkedCards,
              leadcraft?.activeCards,
              leadcraft?.recurringSum
            ),
            createData(
              'AdsFin',
              adsfin?.leads,
              adsfin?.users,
              Number(adsfin?.sentPostbacks) + Number(adsfin?.withheldPostbacks),
              adsfin?.sentPostbacks,
              adsfin?.withheldPostbacks,
              adsfin?.linkedCards,
              adsfin?.activeCards,
              adsfin?.recurringSum
            ),
          ]}
        />
      </div>
      <div style={{ marginTop: 20 }}>
        <ShowcaseTable
          title={'Информация о витринах'}
          rows={[
            createDataShowcase(
              'VK',
              null,
              Number(vk_ads?.paidClicks) +
                Number(vk_ads?.freeClicks) +
                Number(vk_ads?.mfoClicks) +
                Number(vk_ads?.googleClicks) +
                Number(vk_ads?.alertClicks),
              vk_ads?.paidClicks,
              vk_ads?.freeClicks,
              vk_ads?.mfoClicks,
              vk_ads?.googleClicks,
              vk_ads?.alertClicks,
              vk_ads?.sentSms,
              vk_ads?.smsClicks,
              vk_ads?.sentEmail
            ),
            createDataShowcase(
              'Leads',
              null,
              Number(target?.paidClicks) +
                Number(target?.freeClicks) +
                Number(target?.mfoClicks) +
                Number(target?.googleClicks) +
                Number(target?.alertClicks),
              target?.paidClicks,
              target?.freeClicks,
              target?.mfoClicks,
              target?.googleClicks,
              target?.alertClicks,
              target?.sentSms,
              target?.smsClicks,
              target?.sentEmail
            ),
            createDataShowcase(
              'Leads',
              null,
              Number(leads?.paidClicks) +
                Number(leads?.freeClicks) +
                Number(leads?.mfoClicks) +
                Number(leads?.googleClicks) +
                Number(leads?.alertClicks),
              leads?.paidClicks,
              leads?.freeClicks,
              leads?.mfoClicks,
              leads?.googleClicks,
              leads?.alertClicks,
              leads?.sentSms,
              leads?.smsClicks,
              leads?.sentEmail
            ),
            createDataShowcase(
              'Yandex',
              null,
              Number(yandex?.paidClicks) +
                Number(yandex?.freeClicks) +
                Number(yandex?.mfoClicks) +
                Number(yandex?.googleClicks) +
                Number(yandex?.alertClicks),
              yandex?.paidClicks,
              yandex?.freeClicks,
              yandex?.mfoClicks,
              yandex?.googleClicks,
              yandex?.alertClicks,
              yandex?.sentSms,
              yandex?.smsClicks,
              yandex?.sentEmail
            ),
            createDataShowcase(
              'LeadsCraft',
              0,
              Number(leadcraft?.paidClicks) +
                Number(leadcraft?.freeClicks) +
                Number(leadcraft?.mfoClicks) +
                Number(leadcraft?.googleClicks) +
                Number(leadcraft?.alertClicks),
              leadcraft?.paidClicks,
              leadcraft?.freeClicks,
              leadcraft?.mfoClicks,
              leadcraft?.googleClicks,
              leadcraft?.alertClicks,
              leadcraft?.sentSms,
              leadcraft?.smsClicks,
              leadcraft?.sentEmail
            ),
            createDataShowcase(
              'AdsFin',
              null,
              Number(adsfin?.paidClicks) +
                Number(adsfin?.freeClicks) +
                Number(adsfin?.mfoClicks) +
                Number(adsfin?.googleClicks) +
                Number(adsfin?.alertClicks),
              adsfin?.paidClicks,
              adsfin?.freeClicks,
              adsfin?.mfoClicks,
              adsfin?.googleClicks,
              adsfin?.alertClicks,
              adsfin?.sentSms,
              adsfin?.smsClicks,
              adsfin?.sentEmail
            ),
            createDataShowcase(
              'Прямые',
              null,
              Number(straight?.paidClicks) +
                Number(straight?.freeClicks) +
                Number(straight?.mfoClicks) +
                Number(straight?.googleClicks) +
                Number(straight?.alertClicks),
              straight?.paidClicks,
              straight?.freeClicks,
              straight?.mfoClicks,
              straight?.googleClicks,
              straight?.alertClicks,
              straight?.sentSms,
              straight?.smsClicks,
              straight?.sentEmail
            ),
          ]}
        />
      </div>
    </div>
  )
}

export default Dashboard
