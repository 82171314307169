import React from 'react'
import './style.scss'
type Props = {
  title: string
  text1: string
  text2: string
  image: string
}

const Article2Page = (props: any) => {
  React.useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])
  return (
    <div className={'container articles'}>
      <div className={'container__row'}>
        <div className={'articles__title'}>Банкротство</div>
        <div className={'articles__text'}>
          <p>
            Всем нам нужны деньги, иногда они требуются очень срочно, поэтому мы
            прибегаем к помощи кредитов. Часто человек даже не замечает, как
            оброс долгами. Он не справляется с выплатой займов, что лишь
            усугубляет ситуацию. Просроченные долги также вредят кредитной
            репутации.
          </p>
          <p>
            Чтобы этого избежать, можно пройти процедуру банкротства. Она спасет
            вас от кредитов, которые вы уже не в состоянии выплачивать. Не нужно
            бояться этого явления. Некоторые считают, что лучше просрочить
            платежи по выплатам долга, чем оказаться банкротом, но это не так.
          </p>
          <p>
            Конечно, у банкротства есть свои минусы. Вы не сможете выезжать
            заграницу пока идет суд, а банки и финансовые организации с
            подозрением будут относиться к вашей просьбе выдать займ. Однако
            восстановить кредитную репутацию в этом случае намного проще, нежели
            если вы окажитесь заядлым неплательщиком. Теперь поговорим о том,
            что же ждет физическое лицо после банкротства.
          </p>
        </div>
        <div className={'articles__block-img'}>
          <div>
            <img src={'/assets/articles/2.png'} />
          </div>
          <div className={'articles__text'}>
            <h4>Кредитная история после банкротства физ. лица</h4>
            <p>
              Итак, суд признал вас банкротом. После этого решения в вашей
              кредитной истории появляется отметка, что вы банкрот. Теперь, если
              вы захотите взять кредит, вы должны сами сообщать о своем статусе
              финансовой организации. Но если вы решите умолчать об этом, этот
              факт все равно всплывет. Ведь перед принятием решения о выдаче
              кредита, банки и другие МФО проводят проверку. И кроме банкротства
              кредитную историю могут испортить долгие просрочки по платежам и
              нарушения условий договора займа.
            </p>
          </div>
        </div>
        <div className={'articles__text'}>
          <h4>Реально ли исправить кредитную историю после банкротства?</h4>
          <p>
            Не стоит переживать и бояться статуса банкрота. Исправить положение
            вполне реально. Для этого нужно воспользоваться всеми доступными
            способами. Для банкрота основная задача – своевременно погашать
            долги и показать свою платежеспособность по новым кредитам. Более
            того, кредитные компании благосклонно относятся к банкротам. А все
            потому, что они считаются более благоразумными по сравнению с
            неплательщиками. Ко всему прочему, люди со статусом банкротства
            платежеспособнее, ведь они не обременены другими долгами. Именно
            поэтому многие банки готовы выдать кредитную карту должнику, но по
            предоплате.
          </p>
          <p>
            Фактически эти карты не считаются кредитными, так как предполагают
            пополнение депозита и трату собственных средств. Однако это хороший
            способ показать финансовым компаниям, что вы платежеспособны и
            исполнительны. Это повысит ваш кредитный рейтинг.
          </p>
          <p>
            Не рекомендуется превышать сумму задолженности свыше 30% от
            кредитного лимита. Также следует помнить, что данный тип карт
            предполагает высокие процентные ставки на пользование заемными
            средствами.
          </p>
          <p>
            Еще одним хорошим способом исправить свою кредитную репутацию
            является обращение в МФО. Вы можете брать небольшие займы и вовремя
            погашать долг. Это поможет вам улучшить свой кредитный рейтинг среди
            банков и других финансовых компаний.
          </p>
          <p>
            На нашем сайте вы можете найти самые выгодные предложения! Вам нужно
            всего лишь заполнить анкету, а наш агрегатор в кратчайшие сроки
            подберет для вас подходящие условия. Берите только те суммы, которые
            сможете вернуть за определенное время, и тогда вы быстро
            восстановите свой кредитный рейтинг!
          </p>
        </div>
      </div>
    </div>
  )
}

export default Article2Page
