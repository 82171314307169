import {
  Autocomplete,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material'
import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { schemaContact } from './schema'
import { useRequestDaData } from '../../../request/hooks/use-request-dadata'
import { Button } from '../../../components/button'
import { TextMaskCustom } from '../../../components/mask-phone'

const StepContact = (props: any) => {
  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schemaContact),
    defaultValues: {
      phone: (props.state?.phone as string) || '',
      email: (props.state?.email as string) || '',
      city: (props.state?.city as string) || '',
      sex: (props.state?.sex as string) || '',
    },
  })

  React.useEffect(() => {
    if (Object.keys(props.error).length) {
      Object.entries(props.error).map((error: any) => {
        setError(error[0], {
          message: `Такой ${error[0]} существует`,
          type: 'matches',
        })
      })
      props.setErrorForm({})
    }
  }, [props.error])

  const { search, data: listAddress }: { data: any; search: any } =
    useRequestDaData({
      url: 'suggest/address',
      method: 'post',
      params: { query: '' },
    })

  const onSubmit = (data: any) => {
    props.setState((pre: any) => ({ ...pre, ...data }))
    props.setActiveStep((pre: number) => pre + 1)
  }
  if (!listAddress) {
    return null
  }
  return (
    <form onSubmit={handleSubmit(onSubmit)} className={'personal-data'}>
      <div className={'personal-data__block-title'}>
        <div className={'personal-data__block-title__title'}>Ваши контакты</div>
        <div className={'personal-data__block-title__desc'}>
          Заполните данные и получите +25% к одобрению
        </div>
      </div>
      <Controller
        name="phone"
        control={control}
        render={({ field }) => (
          <div className={'personal-data__block-input custom-block'}>
            <label className={'personal-data__label'}>Ваш телефон</label>
            <TextField
              {...field}
              disabled={true}
              id="formatted-numberformat-input"
              placeholder={'7(999)-999-99-99'}
              InputProps={{
                inputComponent: TextMaskCustom,
              }}
              error={!!errors[field.name]?.message}
              helperText={errors[field.name]?.message}
            />
          </div>
        )}
      />
      <Controller
        name="email"
        control={control}
        render={({ field }) => (
          <div className={'personal-data__block-input custom-block'}>
            <label className={'personal-data__label'}>Ваша почта</label>
            <TextField
              {...field}
              type={'email'}
              placeholder={'mail@mail.ru'}
              error={!!errors[field.name]?.message}
              helperText={errors[field.name]?.message}
            />
          </div>
        )}
      />
      <Controller
        name="city"
        control={control}
        render={({ field }) => (
          <Autocomplete
            {...field}
            options={listAddress.suggestions?.map((item: any) => item.value)}
            //@ts-ignore
            onChange={(event, value) => field.onChange(value)}
            disableClearable
            filterOptions={x => x}
            noOptionsText={'Не найдено'}
            renderInput={params => (
              <div>
                <label className={'personal-data__label'}>
                  Город проживания
                </label>
                <TextField
                  {...params}
                  placeholder={'г.Москва'}
                  error={!!errors[field.name]?.message}
                  helperText={errors[field.name]?.message}
                  onChange={event =>
                    search(
                      'suggest/address',
                      { query: event.target.value },
                      'post'
                    )
                  }
                />
              </div>
            )}
          />
        )}
      />
      <Controller
        name="sex"
        control={control}
        render={({ field }) => (
          <div className={'personal-data__block-input custom-block'}>
            <label className={'personal-data__label'}>Пол</label>
            <div>
              <RadioGroup className={'custom-block-radio'} {...field}>
                <FormControlLabel
                  value="women"
                  className={'custom-radio'}
                  control={
                    <Radio
                      icon={
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle cx="12" cy="12" r="9" stroke="#F3F3F3" />
                          <path d="M8 12L11 15L16 9" stroke="#F3F3F3" />
                        </svg>
                      }
                      checkedIcon={
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle cx="12" cy="12" r="9" stroke="#219D8B" />
                          <path d="M8 12L11 15L16 9" stroke="#219D8B" />
                        </svg>
                      }
                    />
                  }
                  label="Женский"
                />
                <FormControlLabel
                  value="men"
                  className={'custom-radio'}
                  control={
                    <Radio
                      icon={
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle cx="12" cy="12" r="9" stroke="#F3F3F3" />
                          <path d="M8 12L11 15L16 9" stroke="#F3F3F3" />
                        </svg>
                      }
                      checkedIcon={
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle cx="12" cy="12" r="9" stroke="#219D8B" />
                          <path d="M8 12L11 15L16 9" stroke="#219D8B" />
                        </svg>
                      }
                    />
                  }
                  label="Мужской"
                />
              </RadioGroup>
              <div className={'errors-helper'}>
                {errors[field.name]?.message}
              </div>
            </div>
          </div>
        )}
      />

      <div className={'personal-data__button-block'}>
        <Button
          title={'Вперед'}
          onClick={handleSubmit(onSubmit) as any}
          theme={'dark'}
        />
        <Button
          title={'Назад'}
          theme={'gray'}
          onClick={() => props.setActiveStep((pre: number) => pre - 1)}
        />
      </div>
    </form>
  )
}

export default StepContact
