export const partners = [
  {
    id: 0,
    searchParams: 'utm_source',
    source: 'linkmoney',
    partner: 'agnitem',
    webmaster: 'utm_webmaster',
    transaction: 'sub_id',
    ref: true,
  },
  {
    id: 1,
    searchParams: 'utm_source',
    source: 'adsfinpro',
    partner: 'adsfin',
    webmaster: 'utm_content',
    transaction: 'sub_id',
    ref: true,
  },
  {
    id: 2,
    searchParams: 'utm_source',
    source: 'adsfin',
    partner: 'utm_source',
    webmaster: 'utm_term',
    transaction: 'utm_content',
    ref: true,
  },
  {
    id: 3,
    searchParams: 'utm_source',
    source: 'leadcraft',
    partner: 'utm_source',
    webmaster: 'campaignID',
    transaction: 'clickID',
    ref: true,
  },
  {
    id: 4,
    searchParams: 'utm_source',
    source: 'vk_ads',
    partner: 'utm_source',
    webmaster: 'utm_campaign',
    transaction: 'utm_content',
    ref: true,
  },
  {
    id: 5,
    searchParams: 'utm_source',
    source: 'target',
    partner: 'utm_source',
    webmaster: 'utm_term',
    transaction: null,
    ref: true,
  },
  {
    id: 6,
    searchParams: 'utm_source',
    source: 'yandex',
    partner: 'utm_source',
    webmaster: 'utm_term',
    transaction: null,
    ref: false,
  },
  {
    id: 7,
    searchParams: 'utm_source',
    source: 'yandex_rsya',
    partner: 'utm_source',
    webmaster: 'utm_term',
    transaction: null,
    ref: false,
  },
  {
    id: 8,
    searchParams: 'affiliate_id',
    source: null,
    partner: 'leads',
    webmaster: 'utm_term',
    transaction: 'transaction_id',
    ref: false,
  },
]
