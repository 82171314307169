import React from 'react'
import { useNavigate } from 'react-router'
import { useLocation, useParams } from 'react-router-dom'
import { Button } from '../../../components/button'
import { requestPayment, requestTraffics } from '../../../request/hooks/api'
import { useRequestAxios } from '../../../request/hooks/use-request-axios'
import { Showcase } from '../../showcase/components/showcase'
import { BlockHeader } from '../components/block-header'
import { ModalPaid } from '../components/modal-paid'
import { ModalPayment } from '../components/modal-payment'

type Props = {
  isBindCard: boolean
  lastName: string
  middleName: string
  firstName: string
}

const RequestTab = (props: Props) => {
  const { data }: { data: any } = useRequestAxios({ url: 'showcases?type=30' })
  const [openModal, setOpenModal] = React.useState<boolean>(false)
  const navigate = useNavigate()
  const location = useLocation()
  const [paid, setPaid] = React.useState(location?.state?.isPaid || false)

  React.useEffect(() => {
    requestTraffics(`/profile/request`, null)
  })

  const handleClose = () => {
    setOpenModal(false)
  }
  const handleClosePayment = () => {
    setPaid(false)
  }
  return (
    <div className={'tab-request'}>
      <ModalPaid
        user={{
          lastName: props.lastName,
          middleName: props.middleName,
          firstName: props.firstName,
        }}
        open={openModal}
        handleClose={handleClose}
      />
      <ModalPayment
        status={location.state?.status}
        user={{
          lastName: props.lastName,
          middleName: props.middleName,
          firstName: props.firstName,
        }}
        open={paid}
        handleClose={handleClosePayment}
      />
      <BlockHeader
        title={
          'После оформления заявки вам станут доступны самые интересные предложения по займам.'
        }
        isButton={true}
        icon={'ok'}
        onClick={() =>
          props.isBindCard
            ? navigate('/showcase', { state: { type: 20 } })
            : setOpenModal(true)
        }
      />

      {props.isBindCard ? (
        <div className={'tab-request__showcase'}>
          <div className={'tab-request__showcase__title text-title'}>
            Предложения от МФО
          </div>
          <div className={'tab-request__showcase__cards'}>
            {data?.offers?.map((item: any) => (
              <Showcase key={item.id} {...item} />
            ))}
          </div>
        </div>
      ) : (
        <div className={'request-tab__block-not-card'}>
          <div className={'request-tab__block-not-card__images'}>
            <img src={'/assets/background/not-card.png'} />
          </div>
          <div className={'request-tab__block-not-card__text'}>
            <div className={'request-tab__block-not-card__text__title'}>
              <img src={`/assets/icons/ok.svg`} /> Лучшие предложения кредитов и
              займов
            </div>
            <div className={'request-tab__block-not-card__text__text'}>
              Уважаемый{' '}
              <span>
                {props.firstName} {props.middleName} {props.lastName}{' '}
              </span>
              , для просмотра лучших предложений от кредитных организаций
              необходимо, активировать доступ( подписку) к нашему сервису. Для
              активации сервиса с вашей карты будет списан 1 рубль, который в
              последующем вернется на вашу карту в течение 1-5 минут. Данная
              процедура является проверкой вашей карты на платежеспособность и
              принадлежности именно Вам, а также для предотвращения
              мошенничества со стороны недобросовестных клиентов.
            </div>
          </div>
          <div className={'request-tab__block-not-card__buttons'}>
            <Button
              theme={'dark'}
              title={'Начать пользоваться'}
              onClick={() => requestPayment()}
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default RequestTab
