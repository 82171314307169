import { maxDate, minDate } from '../../../const/date'
import * as yup from 'yup'

const phoneRegExp = /^((8|\+7)[\- ]?)?(\(?\d{3}\)?[\- ]?)?[\d\- ]{9}$/

export const schemaStepPersonalData = yup
  .object({
    firstName: yup.string().required('Обязательное к заполнению'),
    lastName: yup.string().required('Обязательное к заполнению'),
    birthday: yup
      .date()
      .typeError('Укажите дату вашего рождения')
      .max(new Date(maxDate), 'Ваш возраст менее 18 лет')
      .min(new Date(minDate), 'Ваш возраст более 65 лет')
      .required('Укажите дату вашего рождения'),
  })
  .required()

export const schemaVerificationCode = yup.object({
  phone: yup
    .string()
    .trim()
    .matches(phoneRegExp, 'Укажите телефон')
    .required('Обязательно к заполнению'),
  code: yup.string(),
})
export const schemaContact = yup
  .object({
    phone: yup
      .string()
      .trim()
      .matches(phoneRegExp, 'Укажите телефон')
      .required('Обязательно к заполнению'),
    email: yup
      .string()
      .email('Заполните корректно')
      .required('Обязательно к заполнению'),
    city: yup.string().required('Укажите город'),
    sex: yup.string().required('Выберите значение'),
  })
  .required()

export const schemaPasport = yup
  .object({
    pasportNumber: yup
      .string()
      .trim()
      .length(13, 'Укажите серию и номер')
      .required('Обязательно к заполнению'),
    passportDepartmentCode: yup
      .string()
      .length(7, 'Обязательно к заполнению')
      .required('Обязательно к заполнению'),
    registrationAddress: yup
      .object()
      .test(
        'Укажите город проживания полностью',
        'Укажите город проживания полностью',
        value => {
          return value.street_with_type && value.house
        }
      )
      .required('Укажите город проживания'),
    passportGiveout: yup.string().required('Заполните значение'),
    passportGiveoutDate: yup
      .date()
      .typeError('Укажите дату')
      .required('Заполните значение'),
    checkedDocs: yup
      .boolean()
      .test(
        'Подтвердите согласие',
        'Подтвердите согласие',
        value => value === true
      ),
  })
  .required()
