import React from 'react'

type Props = {
  title: string
  textHelper?: string
  text: string
}

export const Contact = (props: Props) => {
  React.useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])
  return (
    <div className={'contact'}>
      <div className={'contact__title'}>{props.title}</div>
      {props.textHelper && (
        <div className={'contact__text-helper'}>{props.textHelper}</div>
      )}
      <div className={'contact__text'}>{props.text}</div>
    </div>
  )
}
