import React from 'react'
import { requestTraffics } from '../../request/hooks/api'
import { useRequestAxios } from '../../request/hooks/use-request-axios'
import { Props } from '../showcase/components/showcase'
import CardGoogle from './card'
import './style.scss'

const GooglePage = () => {
  document.title = 'займы без отказа - google'

  const { data }: { data: any } = useRequestAxios({
    url: `showcases?type=${40}`,
  })

  React.useEffect(() => {
    requestTraffics(`/showcases?type=40`, null)
    document.getElementById('tg_icon')?.remove()
  })

  return (
    <div className={'google'}>
      <div id="header">
        <div id="topbar">
          <div className="logo">
            <img id="searchbarimage" src="/assets/google/logo.png" />
          </div>
          <div id="searchbar">
            <input id="searchbartext" type="text" value="займы без отказа" />
            <button id="searchbarclose">
              <svg
                focusable="false"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
              >
                <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
              </svg>
            </button>
            <span className="sideline-search"></span>
            <button id="searchbarmic">
              <img src="/assets/google/tia.png" />
            </button>
            <button id="searchbarbutton">
              <svg
                focusable="false"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
              >
                <path d="M15.5 14h-.79l-.28-.27A6.471 6.471 0 0 0 16 9.5 6.5 6.5 0 1 0 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"></path>
              </svg>
            </button>
          </div>
          <div id="bellicon">
            <svg focusable="false" viewBox="0 0 24 24">
              <path d="M6,8c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM12,20c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM6,20c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM6,14c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM12,14c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM16,6c0,1.1 0.9,2 2,2s2,-0.9 2,-2 -0.9,-2 -2,-2 -2,0.9 -2,2zM12,8c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM18,14c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM18,20c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2z"></path>
            </svg>
          </div>
          <a id="profilebutton" href="" target="_top">
            Войти
          </a>
        </div>
        <div id="optionsbar">
          <ul id="optionsmenu1">
            <li id="optionsmenuactive">
              <span className="svg-icon">
                <svg focusable="false" viewBox="0 0 24 24">
                  <path
                    fill="#34a853"
                    d="M10 2v2a6 6 0 0 1 6 6h2a8 8 0 0 0-8-8"
                  ></path>
                  <path
                    fill="#ea4335"
                    d="M10 4V2a8 8 0 0 0-8 8h2c0-3.3 2.7-6 6-6"
                  ></path>
                  <path
                    fill="#fbbc04"
                    d="M4 10H2a8 8 0 0 0 8 8v-2c-3.3 0-6-2.69-6-6"
                  ></path>
                  <path
                    fill="#4285f4"
                    d="M22 20.59l-5.69-5.69A7.96 7.96 0 0 0 18 10h-2a6 6 0 0 1-6 6v2c1.85 0 3.52-.64 4.88-1.68l5.69 5.69L22 20.59"
                  ></path>
                </svg>
              </span>{' '}
              Все
            </li>
            <li>
              <span className="svg-icon">
                <svg focusable="false" viewBox="0 0 24 24">
                  <path d="M12 11h6v2h-6v-2zm-6 6h12v-2H6v2zm0-4h4V7H6v6zm16-7.22v12.44c0 1.54-1.34 2.78-3 2.78H5c-1.64 0-3-1.25-3-2.78V5.78C2 4.26 3.36 3 5 3h14c1.64 0 3 1.25 3 2.78zM19.99 12V5.78c0-.42-.46-.78-1-.78H5c-.54 0-1 .36-1 .78v12.44c0 .42.46.78 1 .78h14c.54 0 1-.36 1-.78V12zM12 9h6V7h-6v2"></path>
                </svg>
              </span>{' '}
              Новости
            </li>
            <li>
              <span className="svg-icon">
                <svg focusable="false" viewBox="0 0 24 24">
                  <path d="M10 16.5l6-4.5-6-4.5v9zM5 20h14a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1H5a1 1 0 0 0-1 1v14a1 1 0 0 0 1 1zm14.5 2H5a3 3 0 0 1-3-3V4.4A2.4 2.4 0 0 1 4.4 2h15.2A2.4 2.4 0 0 1 22 4.4v15.1a2.5 2.5 0 0 1-2.5 2.5"></path>
                </svg>
              </span>{' '}
              Видео
            </li>
            <li>
              <span className="svg-icon">
                <svg focusable="false" viewBox="0 0 24 24">
                  <path d="M21.11 2.89A3.02 3.02 0 0 0 18.95 2h-5.8c-.81 0-1.58.31-2.16.89L7.25 6.63 2.9 10.98a3.06 3.06 0 0 0 0 4.32l5.79 5.8a3.05 3.05 0 0 0 4.32.01l8.09-8.1c.58-.58.9-1.34.9-2.16v-5.8c0-.81-.32-1.59-.89-2.16zM20 10.85c0 .28-.12.54-.32.74l-3.73 3.74-4.36 4.36c-.41.41-1.08.41-1.49 0l-2.89-2.9-2.9-2.9a1.06 1.06 0 0 1 0-1.49l8.1-8.1c.2-.2.46-.3.74-.3l5.8-.01A1.05 1.05 0 0 1 20 5.05v5.8zM16 6c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2"></path>
                </svg>
              </span>{' '}
              Покупки
            </li>
            <li>
              <span className="svg-icon">
                <svg focusable="false" viewBox="0 0 16 16">
                  <path d="M7.503 0c3.09 0 5.502 2.487 5.502 5.427 0 2.337-1.13 3.694-2.26 5.05-.454.528-.906 1.13-1.358 1.734-.452.603-.754 1.508-.98 1.96-.226.452-.377.829-.904.829-.528 0-.678-.377-.905-.83-.226-.451-.527-1.356-.98-1.959-.452-.603-.904-1.206-1.356-1.734C3.132 9.121 2 7.764 2 5.427 2 2.487 4.412 0 7.503 0zm0 1.364c-2.283 0-4.14 1.822-4.14 4.063 0 1.843.86 2.873 1.946 4.177.468.547.942 1.178 1.4 1.79.34.452.596.99.794 1.444.198-.455.453-.992.793-1.445.459-.61.931-1.242 1.413-1.803 1.074-1.29 1.933-2.32 1.933-4.163 0-2.24-1.858-4.063-4.139-4.063zm0 2.734a1.33 1.33 0 11-.001 2.658 1.33 1.33 0 010-2.658"></path>
                </svg>
              </span>{' '}
              Карты
            </li>
            <li>
              <span className="svg-icon">
                <svg
                  focusable="false"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                >
                  <path d="M12 8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"></path>
                </svg>
              </span>{' '}
              Еще
            </li>
          </ul>

          <ul id="optionsmenu2">
            <li>
              <a href="">Инструменты</a>
            </li>
          </ul>
        </div>
      </div>
      <div id="searchresultsarea">
        <p id="searchresultsnumber">
          Результатов: примерно 290 000 (0,51 сек.){' '}
        </p>
        {data?.offers?.map((item: Props) => (
          <CardGoogle
            key={item.id}
            id={item.id}
            isAdvertising={false}
            desc={item.offer.description}
            decorLink={item.offer.realLink}
            title={item.offer.title}
            link={item.link}
          />
        ))}
        <div className="relatedsearches">
          <h3>Связанные запросы</h3>
          <div className="relatedlists">
            <ul className="relatedleft">
              <li>
                <a href="">
                  <span className="span-icon-search"></span>{' '}
                  <span className="span-text-search">
                    займ <b>екапуста</b> на карту
                  </span>
                </a>
              </li>
              <li>
                <a href="">
                  <span className="span-icon-search"></span>{' '}
                  <span className="span-text-search">
                    <b>екапуста</b> под 0%
                  </span>
                </a>
              </li>
              <li>
                <a href="">
                  <span className="span-icon-search"></span>{' '}
                  <span className="span-text-search">
                    <b>money man</b> на карту
                  </span>
                </a>
              </li>
              <li>
                <a href="">
                  <span className="span-icon-search"></span>{' '}
                  <span className="span-text-search">
                    <b>займы без отказа</b>
                  </span>
                </a>
              </li>
            </ul>
            <ul className="relatedright">
              <li>
                <a href="">
                  <span className="span-icon-search"></span>{' '}
                  <span className="span-text-search">
                    <b>займер</b>
                  </span>
                </a>
              </li>
              <li>
                <a href="">
                  <span className="span-icon-search"></span>{' '}
                  <span className="span-text-search">
                    <b>займ на карту</b>
                  </span>
                </a>
              </li>
              <li>
                <a href="">
                  <span className="span-icon-search"></span>{' '}
                  <span className="span-text-search">
                    <b>займы без процентов</b>
                  </span>
                </a>
              </li>
              <li>
                <a href="">
                  <span className="span-icon-search"></span>{' '}
                  <span className="span-text-search">
                    <b>займы без проверок</b> без отказа
                  </span>
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div className="pagebar">
          <ul className="pagelist">
            <li>
              <span
                style={{
                  background: 'url(/assets/google/nav_logo321.png) no-repeat',
                  backgroundPosition: '-24px 0px',
                  width: 28,
                  overflow: 'hidden',
                  height: 40,
                  display: 'block',
                }}
              ></span>
            </li>
            <li>
              <a href="">
                <span
                  style={{
                    background: 'url(/assets/google/nav_logo321.png) no-repeat',
                    backgroundPosition: '-53px 0',
                    width: 20,
                    overflow: 'hidden',
                    height: 40,
                    display: 'block',
                  }}
                ></span>
                1
              </a>
            </li>
            <li>
              <a href="">
                <span
                  style={{
                    background: 'url(/assets/google/nav_logo321.png) no-repeat',
                    backgroundPosition: '-74px 0',
                    width: 20,
                    overflow: 'hidden',
                    height: 40,
                    display: 'block',
                  }}
                ></span>
                2
              </a>
            </li>
            <li>
              <a href="">
                <span
                  style={{
                    background: 'url(/assets/google/nav_logo321.png) no-repeat',
                    backgroundPosition: '-74px 0',
                    width: 20,
                    overflow: 'hidden',
                    height: 40,
                    display: 'block',
                  }}
                ></span>
                3
              </a>
            </li>
            <li>
              <a href="">
                <span
                  style={{
                    background: 'url(/assets/google/nav_logo321.png) no-repeat',
                    backgroundPosition: '-74px 0',
                    width: 20,
                    overflow: 'hidden',
                    height: 40,
                    display: 'block',
                  }}
                ></span>
                4
              </a>
            </li>
            <li>
              <a href="">
                <span
                  style={{
                    background: 'url(/assets/google/nav_logo321.png) no-repeat',
                    backgroundPosition: '-74px 0',
                    width: 20,
                    overflow: 'hidden',
                    height: 40,
                    display: 'block',
                  }}
                ></span>
                5
              </a>
            </li>
            <li>
              <a href="">
                <span
                  style={{
                    background: 'url(/assets/google/nav_logo321.png) no-repeat',
                    backgroundPosition: '-74px 0',
                    width: 20,
                    overflow: 'hidden',
                    height: 40,
                    display: 'block',
                  }}
                ></span>
                6
              </a>
            </li>
            <li>
              <a href="">
                <span
                  style={{
                    background: 'url(/assets/google/nav_logo321.png) no-repeat',
                    backgroundPosition: '-74px 0',
                    width: 20,
                    overflow: 'hidden',
                    height: 40,
                    display: 'block',
                  }}
                ></span>
                7
              </a>
            </li>
            <li>
              <a href="">
                <span
                  style={{
                    background: 'url(/assets/google/nav_logo321.png) no-repeat',
                    backgroundPosition: '-74px 0',
                    width: 20,
                    overflow: 'hidden',
                    height: 40,
                    display: 'block',
                  }}
                ></span>
                8
              </a>
            </li>
            <li>
              <a href="">
                <span
                  style={{
                    background: 'url(/assets/google/nav_logo321.png) no-repeat',
                    backgroundPosition: '-74px 0',
                    width: 20,
                    overflow: 'hidden',
                    height: 40,
                    display: 'block',
                  }}
                ></span>
                9
              </a>
            </li>
            <li>
              <a href="">
                <span
                  style={{
                    background: 'url(/assets/google/nav_logo321.png) no-repeat',
                    backgroundPosition: '-74px 0',
                    width: 20,
                    overflow: 'hidden',
                    height: 40,
                    display: 'block',
                  }}
                ></span>
                10
              </a>
            </li>
            <li>
              <a href="" style={{ textAlign: 'left' }}>
                <span
                  style={{
                    background: 'url(/assets/google/nav_logo321.png) no-repeat',
                    backgroundPosition: '-96px 0',
                    width: 71,
                    overflow: 'hidden',
                    height: 40,
                    display: 'block',
                  }}
                ></span>
                <span style={{ display: 'block', marginLeft: 53 }}>
                  Следующая
                </span>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div id="footer">
        <div className="footer-top">
          <div id="footerlocation">
            Россия{' '}
            <div className="footer-sideline">
              {' '}
              <span className="footer-circle"></span>{' '}
              <span style={{ color: '#3c4043', fontWeight: 'bold' }}>
                Россия
              </span>
              <span style={{ lineHeight: '15px', color: '#70757a' }}>
                {' '}
                - На основе ваших предыдущих действий -{' '}
                <a href="">Учитывать мое местоположение</a> -{' '}
                <a href="">Подробнее…</a>
              </span>{' '}
            </div>
          </div>
        </div>

        <ul id="footermenu">
          <li>
            <a href="">Справка</a>
          </li>
          <li className="mobile">
            <a href="">Отправить отзыв</a>
          </li>
          <li>
            <a href="">Конфиденциальность</a>
          </li>
          <li>
            <a href="">Условия</a>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default GooglePage
