import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { refreshSchema } from './schema'
import { yupResolver } from '@hookform/resolvers/yup'
import { TextField } from '@mui/material'
import './style.scss'
import { Button } from '../../../components/button'
import { useNavigate } from 'react-router-dom'
import { requestVerificationCodes } from '../../../request/hooks/api'

const Reestablish = () => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    resolver: yupResolver(refreshSchema),
    defaultValues: {
      email: '',
    },
  })
  const [isReload, setIsReload] = React.useState(false)
  const navigate = useNavigate()
  const onSubmit = (data: any) => {
    requestVerificationCodes(data.email).finally(() => {
      // requestTraffics(`/reload-password/form`, null)
      setIsReload(true)
    })
  }

  if (isReload) {
    return (
      <div
        className={'register-container reload-password'}
        style={{
          backgroundImage: `url('/assets/background/moneytka.svg')`,
          backgroundRepeat: 'no-repeat',
        }}
      >
        <div className={'register-container__block'}>
          <div className={'register-container__block__title'}>
            Ваше просьба отправлена!
          </div>
          <div className={'register-container__block__text'}>
            Для продолжения востановления подписки, было выслано письмо на Ваш
            почтовый адрес:
            {watch('email')}
          </div>
        </div>
      </div>
    )
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div
        className={'register-container reload-password'}
        style={{
          backgroundImage: `url('/assets/background/moneytka.svg')`,
          backgroundRepeat: 'no-repeat',
        }}
      >
        <div className={'register-container__block'}>
          <div className={'register-container__block__title'}>
            Восстановление пароля
          </div>
          <div className={'register-container__block__text'}>
            Имеется ли у вас доступ к номеру мобильного телефона или электронной
            почте, которые Вы указывали при регистрации?
          </div>
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <div className={'personal-data__block-input custom-block'}>
                <label className={'personal-data__label'}>
                  Почта, указанная при регистрации
                </label>
                <TextField
                  {...field}
                  placeholder={'Введите почту'}
                  error={!!errors[field.name]?.message}
                  helperText={errors[field.name]?.message}
                />
              </div>
            )}
          />
          <Button
            title={'Восстановить пароль'}
            onClick={handleSubmit(onSubmit) as any}
            theme={'dark'}
            className={'register-container__button-login'}
            iconButton={<img src={'/assets/reload.svg'} />}
          />
          <Button
            title={'Я знаю пароль, войти'}
            onClick={() => navigate('/login')}
            theme={'white'}
            className={'register-container__button-register'}
            iconButton={<img src={'/assets/login-green.svg'} />}
          />
        </div>
      </div>
    </form>
  )
}

export default Reestablish
