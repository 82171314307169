import React, { useContext } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Button } from '../../components/button'
import { UserContext } from '../../context/auth'

const HeaderMobile = () => {
  const [checked, setChecked] = React.useState(false)
  const { isAuth, setAuth }: any = useContext(UserContext)

  const navigate = useNavigate()
  return (
    <header className="container-mobile">
      <input
        checked={checked}
        onChange={() => setChecked(pre => !pre)}
        id="toggle"
        type="checkbox"
      />

      <label className="toggle-container" htmlFor="toggle">
        <span className="button button-toggle"></span>
      </label>

      <nav className="nav">
        <Link
          onClick={() => setChecked(pre => !pre)}
          className="nav-item"
          to="/about"
        >
          <div>О сервисе</div>
        </Link>

        <Link
          onClick={() => setChecked(pre => !pre)}
          className="nav-item"
          to="/contacts"
        >
          <div>Контакты</div>
        </Link>
        <Link
          onClick={() => setChecked(pre => !pre)}
          className="nav-item"
          to="/documents"
        >
          <div> Документы</div>
        </Link>
        <Link
          onClick={() => {
            setChecked(pre => !pre)
            window.open('/register', '_blank')
            if (String(localStorage.getItem('showcase_google')) === 'true') {
              return navigate(
                `/google-page?search=${JSON.stringify('займы без отказа')}`
              )
            }
            navigate('/showcase', { state: { type: 10 } })
          }}
          className="nav-item"
          to="/register"
        >
          <div> Оформить займ</div>
        </Link>
        <Link
          to={'/subscription'}
          onClick={() => setChecked(pre => !pre)}
          className="nav-item"
        >
          <div> Наша подписка</div>
        </Link>
        <Link
          onClick={() => setChecked(pre => !pre)}
          className="nav-item"
          to="/login"
        >
          <div>Отменить подписку</div>
        </Link>
        <div className="nav-item" style={{ display: 'flex' }}>
          <Button
            title={'Оформить займ'}
            theme={'white'}
            onClick={() => {
              setChecked(pre => !pre)
              window.open('/register', '_blank')
              if (String(localStorage.getItem('showcase_google')) === 'true') {
                return navigate(
                  `/google-page?search=${JSON.stringify('займы без отказа')}`
                )
              }
              navigate('/showcase', { state: { type: 10 } })
            }}
            style={{ marginRight: 15, height: checked ? 50 : 15 }}
          />

          {isAuth ? (
            <Button
              title={'Выйти'}
              theme={'dark'}
              style={{ height: checked ? 50 : 15 }}
              onClick={() => {
                setChecked(pre => !pre)
                setAuth(false)
                localStorage.removeItem('token')
                localStorage.removeItem('userInfo')
                navigate('/login')
              }}
              iconButton={<img src={'/assets/login.svg'} />}
            />
          ) : (
            <Button
              title={'Войти'}
              theme={'dark'}
              style={{ height: checked ? 50 : 15 }}
              onClick={() => {
                setChecked(pre => !pre)
                navigate('/login')
              }}
              iconButton={<img src={'/assets/login.svg'} />}
            />
          )}
        </div>
      </nav>
      <img
        src={'/assets/logo.svg'}
        onClick={() => navigate('/')}
        style={{ position: 'absolute', right: 16, top: 10 }}
      />
    </header>
  )
}

export default HeaderMobile
