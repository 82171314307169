import axios from 'axios'

type Props = {
  phone: string
  email: string
  lastName: string
  firstName: string
  middleName?: string
  text: string
}

type PropsShowcase = {
  offerName: string
  offerId: string
  offerLink: string
  typeShowcase: string
}

export const sendMessageComplain = (props: Props) => {
  axios(
    `https://api.telegram.org/bot5914549575:AAHYpdFcWzhjVkPAQpipd5wOfIS80C8a9ew/sendMessage?chat_id=-1001811735918&parse_mode=html&text= 🆘🆘🆘Новое обращения❗️❗️❗️ %0A телефон: ${
      props.phone
    }, %0A емаил: ${props.email} %0A фио: ${
      props.lastName + ' ' + props.firstName + ' ' + props?.middleName
    } %0A сообщение: ${props.text} %0A ссылка: https://moneytka.ru/admin/forms/`
  )
}

export const sendMessageIsBingCard = () => {
  const userInfo = JSON.parse(localStorage.getItem('userInfo') as any)
  axios(
    `https://api.telegram.org/bot5914549575:AAHYpdFcWzhjVkPAQpipd5wOfIS80C8a9ew/sendMessage?chat_id=-1001836880114&parse_mode=html&text= ✅✅✅Новая привязка карты✅✅✅ %0A телефон: ${
      userInfo?.phone
    }, %0A емаил: ${userInfo?.email} %0A фио: ${
      userInfo?.lastName +
      ' ' +
      userInfo?.firstName +
      ' ' +
      userInfo?.middleName
    }, %0A token: ${localStorage.getItem('token')}`
  )
}

export const sendMessageIsBingCardError = () => {
  const userInfo = JSON.parse(localStorage.getItem('userInfo') as any)
  axios(
    `https://api.telegram.org/bot5914549575:AAHYpdFcWzhjVkPAQpipd5wOfIS80C8a9ew/sendMessage?chat_id=-1001836880114&parse_mode=html&text= 🆘🆘🆘Ошибка привязки карты🆘🆘🆘 %0A телефон: ${
      userInfo?.phone
    }, %0A емаил: ${userInfo?.email} %0A фио: ${
      userInfo?.lastName +
      ' ' +
      userInfo?.firstName +
      ' ' +
      userInfo?.middleName
    }`
  )
}
export const sendMessageIsBingCardUnsubscribe = () => {
  const userInfo = JSON.parse(localStorage.getItem('userInfo') as any)
  axios(
    `https://api.telegram.org/bot5914549575:AAHYpdFcWzhjVkPAQpipd5wOfIS80C8a9ew/sendMessage?chat_id=-1001836880114&parse_mode=html&text= ❌❌❌Отвязка карты❌❌❌
    %0A телефон: ${userInfo?.phone}, 
    %0A емаил: ${userInfo?.email}
    %0A leadID: ${localStorage.getItem('leadId') || 'Отсутствует'} 
    %0A фио: ${
      userInfo?.lastName +
      ' ' +
      userInfo?.firstName +
      ' ' +
      userInfo?.middleName
    }`
  )
}

export const sendMessageIsOpenOffer = ({
  offerName,
  offerId,
  offerLink,
  typeShowcase,
}: PropsShowcase) => {
  const userInfo = JSON.parse(localStorage.getItem('userInfo') as any)
  const leadId = localStorage.getItem('leadId') || 'Отсутствует'
  axios(
    `https://api.telegram.org/bot5914549575:AAHYpdFcWzhjVkPAQpipd5wOfIS80C8a9ew/sendMessage?chat_id=-1001741697592&parse_mode=html&text= 🆕🆕🆕Новый переход по витрине🆕🆕🆕 
    %0A телефон: ${userInfo?.phone}
    %0A емаил: ${userInfo?.email} 
    %0A фио: ${
      userInfo?.lastName +
      ' ' +
      userInfo?.firstName +
      ' ' +
      userInfo?.middleName
    } 
    %0A leadID: ${leadId}
    %0A тип витрины: ${typeShowcase}
    %0A наименование: ${offerName} 
    %0A offerID: ${offerId} 
    %0A ссылка реферальная:${offerLink}`
  )
}
