import React, { useEffect } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { AddedAdsFin } from '../../../helpers/add-adsFin'
import { requestTraffics } from '../../../request/hooks/api'
import { useRequestAxios } from '../../../request/hooks/use-request-axios'
import { Showcase } from '../components/showcase'
import './style.scss'

const FreeShowcase = (state: any) => {
  const location = useLocation()
  const url =
    location?.state?.['type'] !== 10
      ? `showcases?type=${location?.state?.['type'] || 10}`
      : `showcases?type=${
          location?.state?.['type'] || 10
        }&leadId=${localStorage.getItem('leadId')}`

  const { data, errors }: { data: any; errors: any } = useRequestAxios({
    url: url,
    statusUrl: location?.state?.['type'] !== 10 ? true : false,
  })

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    setTimeout(AddedAdsFin(), 1000)
    requestTraffics(`/showcases?type=${location?.state?.['type'] || 10}`, null)
  }, [])

  return (
    <div className={'container showcase'}>
      <div className={'container__row'}>
        <div className={'showcase__title'}>
          {location?.state?.['type'] === 20
            ? 'Активация сервиса выполнена успешно!*'
            : 'Предварительно одобрено'}
        </div>
        <div className={'showcase__desc'}>
          {location?.state?.['type'] === 20
            ? 'Вам присвоен "VIP" статус и открыт доступ к самым выгодным предложениям!'
            : 'Советуем заполнять максимальное количество заявок на заём — это увеличит шанс на получение желаемой суммы до 100%'}
        </div>
        <div className={'showcase__desc'}>
          {location?.state?.['type'] === 20
            ? 'Рекомендуем заполнить 3-4 заявки, для получения 100% одобрения!'
            : ''}
        </div>
        <div className={'showcase__showcases'}>
          {data?.offers?.map((item: any) => (
            <Showcase
              key={item.id}
              {...item}
              type={location?.state?.['type'] || 10}
            />
          ))}
        </div>
        <div className={'showcase__desc'}>
          {location?.state?.['type'] === 20
            ? '* Благодарим Вас! Вы прошли платную регистрацию на сервисе. Из этого следует по правилам нашего сервиса и договора публичной оферты, Вашу анкету получили более 30 кредитных учреждений! Теперь Вам доступен полный список МФО, в которых мы настоятельно рекомендуем Вам оставить свою анкету, так как этот шаг увеличит вероятность получения займа до 95%. На указанную Вами электронную почту мы отправили электронно-кассовый чек об оплате услуг нашего сервиса.'
            : ''}
        </div>
        <div>
          <ins id="place_110659743060472118"></ins>
        </div>
      </div>
    </div>
  )
}

export default FreeShowcase
