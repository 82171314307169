import * as React from 'react'
import { Step, Stepper } from '@mui/material'
import { styled } from '@mui/material/styles'
import StepPersonalDate from './step-personal-date'
import StepContact from './step-contact'
import StepLast from './step-last'
import StepFirst from './step-first'
import './style.scss'
import StepConnector, {
  stepConnectorClasses,
} from '@mui/material/StepConnector'
import StepPasport from './step-pasport'
import VerificationCode from './verification-code'
import StepFaceLoader from './step-face-loader'

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 0,
    left: 'calc(-50% + 5px)',
    right: 'calc(50px + 5px)',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#219D8B',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#219D8B',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor:
      theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#E9F5F3',
    borderTopWidth: 3,
    borderRadius: 1,
  },
}))

const Register = (props: { location: string }) => {
  const [state, setState] = React.useState({ city: props.location })
  const [error, setError] = React.useState({})
  const [activeStep, setActiveStep] = React.useState(0)
  const steps = ['', '', '', '', '']

  React.useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    document.getElementById('custom_alert')?.remove()
  }, [])

  function ActiveStepper(active: 0 | 1 | 2 | 3 | 4 | 5) {
    switch (active) {
      case 0:
        return (
          <VerificationCode setState={setState} setActiveStep={setActiveStep} />
        )
      case 1:
        return (
          <StepPersonalDate
            state={state}
            setState={setState}
            setActiveStep={setActiveStep}
          />
        )
      case 2:
        return (
          <StepContact
            state={state}
            setState={setState}
            setActiveStep={setActiveStep}
            error={error}
            setErrorForm={setError}
          />
        )
      case 3:
        return (
          <StepPasport
            state={state}
            setState={setState}
            setActiveStep={setActiveStep}
            error={error}
            setErrorForm={setError}
          />
        )
      case 4:
        return (
          <StepLast
            state={state}
            setState={setState}
            setActiveStep={setActiveStep}
            error={error}
            setErrorForm={setError}
          />
        )
      case 5:
        return (
          <StepFaceLoader
            state={state}
            setState={setState}
            setActiveStep={setActiveStep}
            error={error}
            setErrorForm={setError}
          />
        )
    }
  }
  return (
    <div
      className={'register-container'}
      style={{
        backgroundImage: `url('/assets/background/moneytka.svg')`,
        backgroundRepeat: 'no-repeat',
      }}
    >
      <div className={'register'}>
        <Stepper
          alternativeLabel
          activeStep={activeStep}
          sx={{ paddingBottom: 3 }}
          classes={['register__stepper']}
          connector={<QontoConnector />}
        >
          {steps.map(label => (
            <Step key={label}></Step>
          ))}
        </Stepper>
        {ActiveStepper(activeStep as any)}
      </div>
    </div>
  )
}

export default Register
