import React from 'react'
import './style.scss'
type Props = {
  title: string
  text1: string
  text2: string
  image: string
}

const Article3Page = (props: any) => {
  React.useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])
  return (
    <div className={'container articles'}>
      <div className={'container__row'}>
        <div className={'articles__title'}>
          Как не остаться без денег в пандемию?
        </div>
        <div className={'articles__text'}>
          <p>
            Как защитить себя и свой бюджет? В этой статье мы расскажем, как
            защитить себя от коронавируса. Простые советы помогут вам и вашим
            близким избежать заражения.
          </p>
          <p>
            Все помнят, как весной многие люди остались без работы из-за
            карантина. Не все трудовые обязанности можно выполнять удаленно.
          </p>
          <p>
            Большая часть населения практически осталась без средств к
            существованию, ведь никто не был готов к такому повороту судьбы.
          </p>
          <p>
            Многих тогда спасли микрокредиты, которые можно было легко и быстро
            оформить на нашем сайте! Наш портал собрал только проверенные МФО.
          </p>
          <p>
            Заполнив анкету, клиент сразу же получает самые выгодные и удобные
            условия кредитования. Теперь получить деньги можно не выходя из
            дома! Необходимая сумма поступит вам на карту или на банковский
            счет.
          </p>
        </div>
        <div className={'articles__block-img'}>
          <div>
            <img src={'/assets/articles/3.png'} />
          </div>
          <div className={'articles__text'}>
            <h4>Что делать, чтобы не заразиться в толпе?</h4>
            <p>
              Если вам нужно выйти из дома по делам, на работу или в магазин, то
              вам следует помнить о социальной дистанции. Не подходите к
              человеку ближе, чем на 2 метра, ведь он может быть носителем
              вируса. Также старайтесь не выходить из дома, когда на улице много
              людей. И конечно же не забывайте о масках и перчатках. Они
              предотвращают попадание вируса в организм.
            </p>
          </div>
        </div>
        <div className={'articles__text'}>
          <h4>Эффективна ли маска?</h4>
          <p>
            Многие люди сомневаются в эффективности индивидуальных средств
            защиты. А ведь они действительно помогают. Главное, знать, как
            правильно их использовать.
          </p>
          <p>
            Помните, что медицинские маски можно носить не более 2 часов. После
            этого срока её нужно выбросить.
          </p>
          <p>
            Есть многоразовые маски. Они сделаны из плотной ткани и даже имеют
            разнообразные дизайны. Их можно носить весь день, главное потом не
            забыть её постирать.
          </p>
          <p>
            Когда вы находитесь в толпе, да ещё и в закрытом помещении
            (например, в магазине), закрывайте маской не только рот, но и нос.
            Это не позволит коронавирусу попасть на слизистую и проникнуть в
            организм.
          </p>
        </div>
        <div className={'articles__text'}>
          <h4>Как защитить себя от вируса?</h4>
          <p>
            Как было сказано выше, нужно соблюдать социальную дистанцию и носить
            маски и перчатки. Так вы снижаете риск заражения до 90%.
          </p>
          <p>
            После того, как вы пришли с улицы домой, тщательно вымойте руки с
            мылом 25-30 секунд. Это убьёт не только коронавирус, но и другие
            вредные бактерии.
          </p>
          <p>
            А во время пребывания на улице старайтесь не тереть глаза и нос
            руками, вы рискуете заболеть. Но если такая необходимость все же
            возникла (вдруг вам попала соринка в глаз), обработайте руки
            санитайзером.
          </p>
        </div>
      </div>
    </div>
  )
}

export default Article3Page
