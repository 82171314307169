import CircularProgress from '@mui/material/CircularProgress/CircularProgress'
import React from 'react'
import { useLocation, useNavigate, useParams } from 'react-router'
import { Button } from '../../../components/button'
import { requestReloadPassword } from '../../../request/hooks/api'
import './style.scss'

const Refresh = () => {
  const [isReload, setIsReload] = React.useState<Boolean | null>(null)
  const navigate = useNavigate()
  const location = useParams()

  React.useEffect(() => {
    // requestTraffics(`/reload-password`, null)
    requestReloadPassword(location?.token)
      .then(() => setIsReload(true))
      .catch(() => setIsReload(false))
  }, [])

  if (isReload === null) {
    return (
      <div
        style={{
          display: 'flex',
          height: '50vh',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <CircularProgress />
      </div>
    )
  }
  if (isReload) {
    return (
      <div
        className={'register-container reload-password'}
        style={{
          backgroundImage: `url('/assets/background/moneytka.svg')`,
          backgroundRepeat: 'no-repeat',
        }}
      >
        <div className={'register-container__block'}>
          <div className={'register-container__block__title'}>
            Пароль успешно изменен
          </div>
          <div className={'register-container__block__text'}>
            Пароль успешно изменен. Пароль для входа в личный кабинет находится
            уже у вас на почте. Напоминаем, что для входа вам необходим ваш
            номер телефона и пароль, который мы выслали вам на почту.
          </div>
          <Button
            title={'Авторизоваться'}
            onClick={() => navigate('/login')}
            theme={'dark'}
            className={'register-container__button-login'}
            iconButton={<img src={'/assets/login2.svg'} />}
          />
        </div>
      </div>
    )
  }
  return (
    <div
      className={'register-container reload-password'}
      style={{
        backgroundImage: `url('/assets/background/moneytka.svg')`,
        backgroundRepeat: 'no-repeat',
      }}
    >
      <div className={'register-container__block'}>
        <div className={'register-container__block__title'}>
          Что-то пошло не так!
        </div>
        <div className={'register-container__block__text'}>
          Была замечена подозрительная активность. Напоминаем, в случае попытки
          заполучить персональные данные пользователей или использовать наш
          сервис в подобных целях, то мы будем вынуждены забанить ваш аккаунт
          НАВСЕГДА.
        </div>
      </div>
    </div>
  )
}

export default Refresh
