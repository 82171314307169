import React from 'react'
import './App.scss'
import './styles/colors.scss'
import './styles/text.scss'
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom'
import HomePage from './pages/static-pages/home'
import Footer from './pages/footer'
import Header from './pages/header'
import { UserContext } from './context/auth'
import DocumentsPage from './pages/static-pages/documents'
import AboutPage from './pages/static-pages/about'
import ContactsPage from './pages/static-pages/contacts'
import ShowcaseFree from './pages/showcase/free'
import Register from './pages/auth/register'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import ProfilePage from './pages/profile'
import Login from './pages/auth/login'
import Reestablish from './pages/auth/reestablish'
import {
  decodeLink,
  requestAdsFinStatus,
  requestIsWatchPage,
  requestMe,
  requestPaymentStorage,
  requestRenderPayment,
  requestShowcaseGoogleStorage,
  requestTraffics,
} from './request/hooks/api'
import SubscriptionPage from './pages/static-pages/subscription'
import axios from 'axios'
import env from './env'
import Article1Page from './pages/static-pages/articles/Article1'
import Article2Page from './pages/static-pages/articles/Article2'
import Article3Page from './pages/static-pages/articles/Article3'
import Payment from './pages/payment'
import GooglePage from './pages/google'
import Traffics from './pages/traffics-sms'
import AppAnalytics from './analytics'
import { AlertComponent } from './components/alert'
import Refresh from './pages/auth/refresh'
import { RefViewPageContext } from './context/referal-page'

function LocationIP({ setLocation }: { setLocation: (value: string) => void }) {
  axios(env.urlIP, { method: 'get' }).then(data => {
    fetch(`${env.suggestionsUrlDadata}iplocate/address?ip=${data.data.ip}`, {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: env.tokenDadata,
        Accept: 'application/json',
      },
    })
      .then(country => country.json())
      .then(value => {
        localStorage.setItem('city', value?.location?.value)
        setLocation(value?.location.value || '')
      })
  })
}

const App = () => {
  const [isAuth, setAuth] = React.useState(false)
  const [viewPage, setViewPage] = React.useState<0 | 1 | 2>(0)
  const [location, setLocation] = React.useState('')

  React.useEffect(() => {
    requestAdsFinStatus()
    requestRenderPayment()
    requestIsWatchPage()
  })

  React.useEffect(() => {
    if (new URL(document.URL).searchParams.get('l')) {
      decodeLink({ link: new URL(document.URL).searchParams.get('l') }).then(
        data => (window.location.href = data.data.link || 'https://moneytka.ru')
      )
    }
  }, [])

  React.useEffect(() => {
    requestPaymentStorage()
    if (localStorage.getItem('city')) {
      setLocation(String(localStorage.getItem('city')))
    } else {
      LocationIP({ setLocation })
    }
  }, [])

  React.useEffect(() => {
    requestShowcaseGoogleStorage()
    window.scrollTo(0, 0)
    setTimeout(() => requestTraffics(`/`, null), 1000)
  }, [])

  React.useEffect(() => {
    if (localStorage.getItem('token')) {
      requestMe()
        .then(response => {
          setAuth(true)
          if (!response.data.isFirstPayment) {
            localStorage.setItem('ref', '0')
          }
          localStorage.setItem('userInfo', JSON.stringify(response.data))
        })
        .catch(() => localStorage.removeItem('token'))
    }
  }, [])

  window.addEventListener(
    'popstate',
    function (e) {
      var id = e.state ? e.state.count : localStorage.id - 1,
        dir = id
      if (!dir && document.location.pathname === '/') {
        window.history.replaceState(
          {},
          '',
          `/google-page?search=${JSON.stringify('займы без отказа')}`
        )
      }
    },
    false
  )

  return (
    <React.Fragment>
      <AlertComponent />
      <RefViewPageContext.Provider value={{ viewPage, setViewPage }}>
        <UserContext.Provider value={{ isAuth, setAuth }}>
          <BrowserRouter>
            <Header city={location || ''} />
            <img
              src={'/assets/tg.png'}
              onClick={() =>
                window.open('https://t.me/test_Kristina_22_bot?start=welcome')
              }
              id={'tg_icon'}
              className={'tg-icon'}
            />
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route
                path="/registration/api/:partner/:webmaster/:transactionId/"
                element={<HomePage />}
              />
              <Route path={'/google-page/*'} element={<GooglePage />} />
              <Route path="/documents" element={<DocumentsPage />} />
              <Route path={'/about'} element={<AboutPage />} />
              <Route path={'/contacts'} element={<ContactsPage />} />
              <Route path={'/showcase'} element={<ShowcaseFree />} />
              {isAuth && <Route path={'/profile'} element={<ProfilePage />} />}
              <Route path={'/subscription'} element={<SubscriptionPage />} />
              <Route path={'/articles/1'} element={<Article1Page />} />
              <Route path={'/articles/2'} element={<Article2Page />} />
              <Route path={'/articles/3'} element={<Article3Page />} />
              <Route path={'/bind-success'} element={<Payment />} />
              <Route path={'/traffics/*'} element={<Traffics />} />
              {!isAuth && (
                <Route
                  path={'/restore-password/:token'}
                  element={<Refresh />}
                />
              )}
              <Route path={'/?l='} element={<Traffics />} />
              {!isAuth && (
                <Route path="/reload-password" element={<Reestablish />} />
              )}
              {!isAuth && <Route path="/login" element={<Login />} />}

              <Route path={'/analytics/*'} element={<AppAnalytics />} />
              {!isAuth && (
                <Route
                  path={'/register'}
                  element={<Register location={location} />}
                />
              )}
              {!isAuth && <Route path="*" element={<HomePage />} />}
              {isAuth && <Route path="*" element={<ProfilePage />} />}
            </Routes>
            {viewPage === 2 ? <Footer /> : ''}
          </BrowserRouter>
        </UserContext.Provider>
      </RefViewPageContext.Provider>
    </React.Fragment>
  )
}

export default App
