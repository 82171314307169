import {
  Autocomplete,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  TextField,
} from '@mui/material'
import React, { useContext } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { schemaPasport } from './schema'
import { useRequestDaData } from '../../../request/hooks/use-request-dadata'
import { Button } from '../../../components/button'
import { requestPayment, requestRegister } from '../../../request/hooks/api'
import { useNavigate } from 'react-router'
import { UserContext } from '../../../context/auth'
import { TextMaskCustomCode } from '../../../components/mask-code'
import { documents } from '../../../static-data/documents'
import SelectDay from '../../../components/SelectDay'
const optionsDate = [
  2023, 2022, 2021, 2020, 2019, 2018, 2017, 2016, 2015, 2014, 2013, 2012, 2011,
  2010, 2009, 2008, 2007, 2006, 2005, 2004, 2003, 2001, 2000, 1999, 1998, 1997,
  1996, 1995, 1994, 1993, 1992, 1991, 1990, 1989, 1988, 1987, 1986, 1985, 1984,
  1983, 1982, 1981, 1980, 1979, 1978, 1977, 1976, 1975, 1974, 1973, 1972, 1971,
  1970, 1969, 1968, 1967, 1966, 1965, 1964, 1963, 1962, 1961,
]

const StepPasport = (props: any) => {
  const navigate = useNavigate()
  const { setAuth }: any = useContext(UserContext)
  const [loader, setLoader] = React.useState<boolean>(false)
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schemaPasport),
    defaultValues: {
      pasportNumber: (props.state.pasportNumber as string) || '',
      passportDepartmentCode:
        (props.state.passportDepartmentCode as string) || '',
      registrationAddress: (props.state.registrationAddress as string) || '',
      passportGiveout: (props.state.passportGiveout as string) || '',
      passportGiveoutDate: props.state.passportGiveoutDate || '',
      checkedDocs: props.state.checkedDocs || false,
    },
  })

  React.useEffect(() => {
    //@ts-ignore
    ym(93059549, 'reachGoal', 'validtion1')
  }, [watch('checkedDocs')])

  const { search, data: listPasport }: { data: any; search: any } =
    useRequestDaData({
      url: 'suggest/fms_unit',
      method: 'post',
      params: { query: '' },
    })

  const onSubmit = (data: any) => {
    props.setState((pre: any) => ({ ...pre, ...data }))
    // setLoader(true)
    props.setActiveStep((pre: number) => pre + 1)
  }
  if (!listPasport) {
    return null
  }

  return (
    <form className={'personal-data'}>
      <div className={'personal-data__block-title'}>
        <div className={'personal-data__block-title__title'}>
          Паспортные данные
        </div>
        <div className={'personal-data__block-title__desc'}>
          Заполните данные и получите +15% к одобрению
        </div>
      </div>
      <div className={'personal-data__block-input custom-block'}>
        <Controller
          name="pasportNumber"
          control={control}
          render={({ field }) => (
            <div className={'personal-data__block-input custom-block'}>
              <label className={'personal-data__label'}>
                Серия / номер паспорта
              </label>
              <TextField
                {...field}
                placeholder={'9999 / 999999'}
                error={!!errors[field.name]?.message}
                helperText={errors[field.name]?.message}
                InputProps={{
                  inputComponent: TextMaskCustomCode,
                }}
              />
            </div>
          )}
        />
        <Controller
          name="passportDepartmentCode"
          control={control}
          render={({ field }) => (
            <Autocomplete
              {...field}
              onFocus={() => search('suggest/fms_unit', { query: '' }, 'post')}
              options={listPasport.suggestions?.map(
                (item: any) => item.data.code + ' ' + item.value
              )}
              filterOptions={x => x}
              onChange={(event, value) => {
                setValue('passportGiveout', value.substr(8))
                field.onChange(value.substr(0, 7))
              }}
              disableClearable
              noOptionsText={'Не найдено'}
              renderInput={params => (
                <div>
                  <label className={'personal-data__label'}>
                    Код подразделения
                  </label>
                  <TextField
                    {...params}
                    placeholder={'010-000'}
                    error={!!errors[field.name]?.message}
                    helperText={errors[field.name]?.message}
                    required={true}
                    onChange={event =>
                      search(
                        'suggest/fms_unit',
                        { query: event.target.value },
                        'post'
                      )
                    }
                  />
                </div>
              )}
            />
          )}
        />
        <Controller
          name="passportGiveout"
          control={control}
          render={({ field }) => (
            <Autocomplete
              {...field}
              onFocus={() => search('suggest/fms_unit', { query: '' }, 'post')}
              filterOptions={x => x}
              options={listPasport.suggestions?.map(
                (item: any) => item.data.code + ' ' + item.value
              )}
              onChange={(event, value) => {
                field.onChange(value.substr(8))
              }}
              disableClearable
              noOptionsText={'Не найдено'}
              renderInput={params => (
                <div>
                  <label className={'personal-data__label'}>
                    Кем выдан паспорт
                  </label>
                  <TextField
                    {...params}
                    placeholder={'ТП УФМС МОСКВЫ'}
                    error={!!errors[field.name]?.message}
                    helperText={errors[field.name]?.message}
                    onChange={event =>
                      search(
                        'suggest/fms_unit',
                        { query: event.target.value },
                        'post'
                      )
                    }
                  />
                </div>
              )}
            />
          )}
        />
      </div>
      <Controller
        name="passportGiveoutDate"
        control={control}
        render={({ field }) => (
          <SelectDay
            {...field}
            options={optionsDate}
            label={'Дата выдачи паспорта'}
            errorText={errors[field.name]?.message || false}
          />
        )}
      />
      <Controller
        name="registrationAddress"
        control={control}
        render={({ field }) => (
          <Autocomplete
            {...field}
            onFocus={() => search('suggest/address', { query: '' }, 'post')}
            options={listPasport.suggestions}
            //@ts-ignore
            onChange={(event, value: any) =>
              field.onChange({ ...value?.data, value: value.value })
            }
            disableClearable
            noOptionsText={'Не найдено'}
            filterOptions={x => x}
            getOptionLabel={(option: any) => option.value || ''}
            renderInput={params => (
              <div>
                <label className={'personal-data__label'}>
                  Полный адрес регистрации
                </label>
                <TextField
                  {...params}
                  placeholder={'г. Москва ул. Ленина д. 1 кв. 1'}
                  error={!!errors[field.name]?.message}
                  helperText={errors[field.name] && 'Заполните адрес полностью'}
                  onChange={event =>
                    search(
                      'suggest/address',
                      { query: event.target.value },
                      'post'
                    )
                  }
                />
              </div>
            )}
          />
        )}
      />

      <div className={'text-error'} style={{ marginTop: 20 }}>
        {errors['checkedDocs']?.message as any}
      </div>

      <Controller
        name="checkedDocs"
        control={control}
        render={({ field }) => (
          <FormControlLabel
            {...field}
            label={''}
            defaultChecked={true}
            control={
              <>
                <Checkbox
                  color={'success'}
                  checked={watch('checkedDocs')}
                  {...field}
                  defaultChecked={true}
                />
                <div style={{ fontSize: '12px', textAlign: 'justify' }}>
                  Даю согласие на{' '}
                  <a
                    href={documents[4].link}
                    className={'na-text-weight-bold'}
                    target={'_blank'}
                  >
                    <i>
                      <b> обработку персональных данных</b>
                    </i>
                  </a>{' '}
                  и получение{' '}
                  <a
                    href={documents[0].link}
                    className={'na-text-weight-bold'}
                    target={'_blank'}
                  >
                    <i>
                      <b>
                        {' '}
                        материалов рекламного характера (Email и SMS рассылка)
                      </b>
                    </i>
                  </a>{' '}
                  , а так же я согласен с{' '}
                  <a
                    href={documents[2].link}
                    className={'na-text-weight-bold'}
                    target={'_blank'}
                  >
                    <i>
                      <b>договором публичной оферты</b>
                    </i>
                  </a>
                  .{' '}
                  <i>
                    <br />
                    Для активации сервиса с вашей карты будет списан один
                    рубль,который будет сразу возвращен обратно на ту же карту.
                    Таким способом мы проверим Вашу карту на работоспособность и
                    принадлежность именно Вам.
                  </i>
                  <br />
                  Все данные зашифрованы по стандарту DES. Для повышения
                  удобства работы с сайтом Монетка использует файлы cookie. В
                  cookie содержатся данные о прошлых посещениях сайта. Если вы
                  не хотите, чтобы эти данные обрабатывались, отключите cookie в
                  настройках браузера.
                </div>
              </>
            }
          ></FormControlLabel>
        )}
      />
      {loader ? (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress color={'success'} />
        </div>
      ) : (
        <div
          className={'personal-data__button-block'}
          style={{ marginBottom: 20 }}
        >
          <Button
            title={'Далее'}
            onClick={handleSubmit(onSubmit as any)}
            theme={'dark'}
          />
          <Button
            title={'Назад'}
            theme={'gray'}
            onClick={() => props.setActiveStep((pre: number) => pre - 1)}
          />
        </div>
      )}
    </form>
  )
}

export default StepPasport
