import * as React from 'react'
import { alpha } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import { visuallyHidden } from '@mui/utils'

interface Data {
  name: string | null
  count_all: number | null
  count_paid: number | null
  count_free: number | null
  count_google: number | null
  count_mfo: number | null
  count_alert: number | null
  sent_sms: number | null
  count_sms: number | null
  count_email: number | null
  count_all_offers: number | null
}

export function createDataShowcase(
  name: string | null,
  count_all: number | null,
  count_all_offers: number | null,
  count_paid: number | null,
  count_free: number | null,
  count_google: number | null,
  count_mfo: number | null,
  count_alert: number | null,
  sent_sms: number | null,
  count_sms: number | null,
  count_email: number | null
): Data {
  return {
    name,
    count_all,
    count_all_offers,
    count_paid,
    count_free,
    count_google,
    count_mfo,
    count_alert,
    sent_sms,
    count_sms,
    count_email,
  }
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

type Order = 'asc' | 'desc'

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) {
      return order
    }
    return a[1] - b[1]
  })
  return stabilizedThis.map(el => el[0])
}

interface HeadCell {
  disablePadding: boolean
  id: keyof Data
  label: string
  numeric: boolean
}

const headCells: readonly HeadCell[] = [
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Партнер',
  },
  {
    id: 'count_all',
    numeric: true,
    disablePadding: true,
    label: 'Всего переходов на витрины',
  },
  {
    id: 'count_all_offers',
    numeric: true,
    disablePadding: true,
    label: 'Клики по офферам',
  },
  {
    id: 'count_paid',
    numeric: true,
    disablePadding: true,
    label: 'Платные офферы',
  },
  {
    id: 'count_free',
    numeric: true,
    disablePadding: true,
    label: 'Бесплатные офферы',
  },
  {
    id: 'count_mfo',
    numeric: true,
    disablePadding: true,
    label: 'МФО офферы',
  },
  {
    id: 'count_google',
    numeric: true,
    disablePadding: true,
    label: 'Google офферы',
  },

  {
    id: 'count_alert',
    numeric: true,
    disablePadding: true,
    label: 'Модальные офферы',
  },
  {
    id: 'sent_sms',
    numeric: true,
    disablePadding: true,
    label: 'Отправлено SMS',
  },
  {
    id: 'count_sms',
    numeric: true,
    disablePadding: true,
    label: 'Переходы по SMS',
  },
  {
    id: 'count_email',
    numeric: true,
    disablePadding: false,
    label: 'Отправлено email',
  },
]

interface EnhancedTableProps {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => void
  order: Order
  orderBy: string
  rowCount: number
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort } = props
  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property)
    }

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            sx={{ fontWeight: '600' }}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

interface EnhancedTableToolbarProps {
  title: string
}

function EnhancedTableToolbar(props: EnhancedTableToolbarProps) {
  return (
    <Toolbar>
      <Typography
        sx={{ flex: '1 1 100%' }}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        {props.title}
      </Typography>
    </Toolbar>
  )
}

export default function ShowcaseTable({
  title,
  rows,
}: {
  title: string
  rows: Data[]
}) {
  const [order, setOrder] = React.useState<Order>('asc')
  const [orderBy, setOrderBy] = React.useState<keyof Data>('name')
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(5)

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <EnhancedTableToolbar title={title} />
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={'medium'}
          >
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {rows.map((row, index) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.name}>
                    <TableCell component="th" scope="row">
                      {row.name}
                    </TableCell>
                    <TableCell align="right">{row.count_all}</TableCell>
                    <TableCell align="right">{row.count_all_offers}</TableCell>
                    <TableCell align="right">{row.count_paid}</TableCell>
                    <TableCell align="right">{row.count_free}</TableCell>
                    <TableCell align="right">{row.count_mfo}</TableCell>
                    <TableCell align="right">{row.count_google}</TableCell>
                    <TableCell align="right">{row.count_alert}</TableCell>
                    <TableCell align="right">{row.sent_sms}</TableCell>
                    <TableCell align="right">{row.count_sms}</TableCell>
                    <TableCell align="right">{row.count_email}</TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  )
}
