import React from 'react'
import { requestTraffics } from '../../../request/hooks/api'
import { BlockHeader } from '../components/block-header'
import { CardVerification } from './card'

type Props = {
  emailApproved: boolean
  fioApproved: boolean
  passportApproved: boolean
  phoneApproved: boolean
  isBindCard: boolean
}

const VerificationTab = (props: Props) => {
  React.useEffect(() => {
    // requestTraffics(`/profile/verification`, null)
  })
  return (
    <div className={'tab-verification'}>
      <BlockHeader
        title={
          Object.entries(props).filter(item => item[1] === false).length
            ? 'Верификация не пройдена'
            : 'Верификация пройдена'
        }
        text={
          Object.entries(props).filter(item => item[1] === false).length
            ? 'Пожалуйста пройдите верификацию для полного одобрения'
            : 'Поздравляем, вы успешно прошли верификацию полного одобрения для МФО.'
        }
        isButton={false}
        icon={
          Object.entries(props).filter(item => item[1] === false).length
            ? 'bad'
            : 'ok'
        }
      />
      <div className={'tab-request__showcase'}>
        <div className={'tab-request__showcase__title text-title'}>
          Верификация пользователя
        </div>
      </div>
      <div className={'tab-verification__block'}>
        <CardVerification
          title={
            props.fioApproved
              ? 'ФИО прошло верификацию'
              : 'ФИО не прошло верификацию'
          }
          icon={props.fioApproved ? 'ok' : 'bad'}
        />
        <CardVerification
          title={
            props.phoneApproved
              ? 'Телефон прошел верификацию'
              : 'Телефон не прошел верификацию'
          }
          icon={props.phoneApproved ? 'ok' : 'bad'}
        />
        <CardVerification
          title={
            props.emailApproved
              ? 'Email прошел верификацию'
              : 'Email не прошел верификацию'
          }
          icon={props.emailApproved ? 'ok' : 'bad'}
        />
        <CardVerification
          title={
            props.passportApproved
              ? 'Паспорт прошел верификацию'
              : 'Паспорт не прошел верификацию'
          }
          icon={props.passportApproved ? 'ok' : 'bad'}
        />
        <CardVerification
          title={props.isBindCard ? 'Подписка активна' : 'Подписка не активна'}
          icon={props.isBindCard ? 'ok' : 'bad'}
        />
      </div>
    </div>
  )
}

export default VerificationTab
